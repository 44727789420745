import { UserOutlined } from '@ant-design/icons';
import { Col, Pagination, Rate, Row, Skeleton, Avatar, Empty } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { loadReviewsCurso } from '../../actions/maestros';

export const TabReviewsCurso = ({_id, dispatch}) => {
    const [reviews, setReviews] = useState({
        status: false,
        reviews: [],
        total: 5
    });

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
      dispatch(loadReviewsCurso(1, _id, setReviews));
    }, [dispatch, _id])

    const handleCambioPagina = (pag) => {
        setReviews({
            ...reviews,
            status: false
        });

        setCurrentPage(pag);
        dispatch(loadReviewsCurso(pag, _id, setReviews));
    }

    return (
        <div style={{backgroundColor: 'white', padding: '30px'}}>  
            <Row gutter={[12,24]}>
                <Col span={24}>
                    {
                        (reviews.status === true) ? (
                            (reviews.reviews.length > 0) ?
                                reviews.reviews.map((item, key) => (
                                        <div key={key} className='tab-reviews-card'>
                                            <div style={{display: 'flex', alignItems: 'center'}}>
                                            <img className="tab-reviews-img" src={item.usuario.img===null ? <Avatar icon={<UserOutlined />} size="large" /> : `${item.usuario.img}`} alt="Imagen usuario"/>
                                            <div>
                                                <div>{item.usuario.nombre + ' ' + item.usuario.apellidos}</div>
                                                <div>Publicado el: {item.fecha_rate}</div>
                                                <Rate allowHalf disabled value={Number(item.rate)} />
                                            </div>
                                        </div>
                                        <div className="tab-reviews-review"> {item.rate_comment} </div>
                                    </div> 
                                ))
                            : 
                            <Empty description='No data' />
                        )
                        :
                        (
                            ([0,1,2,3,4,5,6,7]).map(n => (
                                <Col key={n} className="gutter-row" xs={24} md={15}>
                                    <Skeleton title={false} active paragraph={{rows: 3 }} />
                                </Col>
                            ))
                        )
                    }
                </Col>

                <Col style={{textAlign: 'center'}} span={24}>
                    <Pagination current={currentPage} onChange={handleCambioPagina} pageSize={3} total={reviews.total} showSizeChanger={false}/>
                </Col>
            </Row>
            
        </div>
    )
}
