import { Card } from 'antd'
import React from 'react'

export const TarjetaCursoDocente = ({ curso, portada}) => {
    return (
        <>
            <Card
                cover={<img style={{height: '140px'}} alt="Podcast" src={(curso.img) ? curso.img : portada} />}
                style={{boxShadow: '2px 2px 7px #c4c4c4'}}
            >
                <Card.Grid hoverable={false} style={{width: '100%', height:'110px', padding: '15px 15px 15px 15px'}}>
                    <div className="card-titulo bold">{curso.nombre}</div>
                </Card.Grid>

                <Card.Grid hoverable={false} style={{width: '100%', backgroundColor: '#ededed', padding: '10px 15px 10px 15px'}}>
                    <div className="card-maestro-footer">
                        {
                            (curso.status === 0)
                                ?
                                    (
                                        <div style={{color: 'grey', fontSize: '10px', fontWeight: 'bold'}}>
                                            NO PUBLICADO
                                        </div>
                                    )
                                :
                                    (
                                        <div style={{color: 'var(--primary)', fontSize: '10px', fontWeight: 'bold'}}>
                                            PUBLICADO
                                        </div>
                                    )
                        }
                    </div>
                </Card.Grid>
            </Card>
            
        </>
    )
}
