
import React, { useState } from 'react';

import { Button, Form, Input } from 'antd';
import Modal from 'antd/lib/modal/Modal';
import { defaultValidateMessages } from '../../helpers/validateMessages';
import { useHistory, withRouter } from 'react-router-dom';
import { crearNuevoCurso } from '../../actions/maestros';
import { useDispatch } from 'react-redux';

export const ModalAddCurso = ({vis, setVis}) => {

    const history = useHistory();

    const dispatch = useDispatch();

    const [redirect, setRedirect] = useState({
        status: false,
        courseId: ''
    });

    const handleCancelar = () => {
        setVis(false);
    }

    const handleCrearCurso = (data) => {
        dispatch(crearNuevoCurso(data.nombre, setVis, setRedirect));
    }

    if(redirect.status === true){
        const url = `/docente/courses/${redirect.courseId}`;
        history.push(url);
    }

    return (
        <div>
            <Modal
                visible={vis}
                destroyOnClose={true}
                closable={false}
                footer={null}
            >
                <h2>Crea un nuevo curso</h2>
                <Form 
                    onFinish={handleCrearCurso}
                    style={{paddingTop:'15px'}}
                    validateMessages={defaultValidateMessages}
                >
                    <Form.Item
                        name="nombre"
                        rules={[
                            {
                                required: true
                            }
                        ]}
                    >
                        <Input placeholder='Nuevo Curso' />
                    </Form.Item>

                    <div style={{display:'flex' ,width:'100%', justifyContent:'flex-end'}}>
                        <Form.Item>
                            <Button  onClick={() => handleCancelar()} >Cancelar</Button>
                        </Form.Item>
                        <Form.Item>
                                <Button htmlType="submit" style={{backgroundColor: 'var(--primary)', color: 'white' , marginLeft: '5px'}}>Aceptar</Button>
                        </Form.Item>
                    </div>

                </Form>
            </Modal>
            
        </div>
    )
}

export default withRouter(ModalAddCurso);
