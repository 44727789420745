import React from 'react';
import { UserOutlined,MailOutlined } from '@ant-design/icons';
import {  Button, Form, Input } from 'antd';
import { defaultValidateMessages } from '../../helpers/validateMessages';
import { useDispatch } from 'react-redux';
import { editarMaestroProf } from '../../actions/maestros';

const { TextArea } = Input;

export const ProfMaestro = ({_id,profesion,resumen}) => {
    const dispatch = useDispatch();
    const initValues = {
        profesion: profesion,
        resumen: resumen,
    }
    const handleEditar=(datos)=>{
        dispatch(editarMaestroProf(_id,datos));
    }
    

    return (
        <div>
             <Form
                initialValues={initValues}
                onFinish={handleEditar}
                validateMessages={defaultValidateMessages}
                >

                <Form.Item
                    name="profesion"
                    rules={[
                    {
                        required: true,
                        min:8
                        
                    },
                    ]}
                >
                    <Input
                    prefix={<MailOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />} 
                    placeholder="Profesion" 
                    type="text"
                    className="inputs"
                    />
                </Form.Item>

                <Form.Item
                    name="resumen"
                    rules={[
                    {
                        required: true,
                        max:150
                    },
                    ]}
                >
                    <TextArea
                    prefix={<UserOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />}
                    placeholder="Resumen"
                    rows={4}
                    className="inputs"
                    />
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" className="btn-forms">
                        Guardar
                    </Button>
                </Form.Item>
                </Form>
        </div>
    )
}
