import React from 'react'
import { Skeleton, Row, Col } from 'antd'

export const SkeletonAdminCursoScreen = () => {
    return (
        <div className="admin-curso-card">
            <Row className="row-padding" justify="space-between">
                <Col span={16}>
                    <Skeleton.Button block size="default" />
                </Col>
                <Row className="row-padding admin-curso-side" justify="center" align="center">
                    <Col>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Skeleton.Avatar style={{width: '50px !important', height: '50px !important'}}/>
                            <div className="admin-curso-side-item">
                                <h5 className="admin-curso-title-side">Maestro</h5>
                                <Skeleton active title={false} paragraph={{rows: 1}}/>
                            </div>
                        </div>
                    </Col>
                    <Col>
                        <div className="admin-curso-side-item">
                            <h5 className="admin-curso-title-side">Calificación</h5>
                            <Skeleton active title={false} paragraph={{rows: 1}}/>
                        </div>
                    </Col>
                    <Col>
                        <div className="admin-curso-side-item">
                            <h5 className="admin-curso-title-side">Participantes</h5>
                            <div style={{display: 'flex', textAlign: 'center', justifyContent: 'center'}} >
                                <Skeleton active title={false} paragraph={{rows: 1}}/>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Row>

            <Row gutter={[16, 24]} className="row-padding">
                <Col xs={24} lg={6}>
                    <Skeleton.Image style={{width: '200px', height: '200px'}}/>
                </Col>
                <Col xs={24} lg={18}>
                    <div className="admin-curso-outline">
                        <div className="admin-curso-subtitle">Descripción</div>
                        <Skeleton active />
                    </div>
                </Col>
            </Row>

            <Row gutter={[16, 24]} className="row-padding">
                <Col xs={24} lg={6}>
                    <div className= "admin-curso-outline">
                        <div className="admin-curso-subtitle" style={{textAlign: 'center'}}>Características</div>
                        <div>
                            <div className="admin-curso-caracteristica" style={{alignItems: 'center'}}>
                                
                                <Skeleton active title={false} paragraph={{rows: 1, width: '100%'}}/>
                            </div>
                            <div className="admin-curso-caracteristica" style={{alignItems: 'center'}}>
                                
                                <Skeleton active title={false} paragraph={{rows: 1, width: '100%'}}/>
                            </div>
                            <div className="admin-curso-caracteristica" style={{alignItems: 'center'}}>
                               
                                <Skeleton active title={false} paragraph={{rows: 1, width: '100%'}}/>
                            </div>
                            <div className="admin-curso-caracteristica" style={{alignItems: 'center'}}>
                                <Skeleton active title={false} paragraph={{rows: 1, width: '100%'}}/>
                            </div>
                            <div className="admin-curso-categorias">
                            <Skeleton active title={false} paragraph={{rows: 1, width: '100%'}}/>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col xs={24} lg={18}>
                    <div className="admin-curso-subtitle" style={{textAlign: 'center'}}>Contenido</div>
                    <div>
                        <Skeleton active />
                    </div>
                </Col>
            </Row>
        </div>
    )
}
