
export const types = {

    authCheckingFinish: '[auth] Finish checking login state',
    authStartLogin: '[auth] Start Login',
    authLogin: '[auth] Login',
    authLogout: '[auth] Logout',

    eventSetActive:'[event] Set Active',
    eventClearActiveEvent: '[event] Clear active event',

    maestrosLoaded:'[event] Maestros loaded',
    usuariosLoaded:'[event] Usuarios loaded',
    usuariosLogin: '[event] Login de usuario',
    eventUpdate:'[event] Event updated',
    actividadesLoaded:'[event] Actividades loaded',
    actividadLoaded:'[event] Actividad loaded',
    actividadClear:'[event] Actividad clear',

    postsLoaded:'[event] Posts loaded',
    postsClear:'[event] Posts clear',

    cursoUsuarioLoaded:'[event] Curso Loaded',
    valoracionesLoaded:'[event] valoraciones loaded',
    bienvenidaUsuario:'[event] bienvenida usuario',
    carritoUsuario:'[event] carrito usuario',
    courseTracker:'[event]  course tracker',
    resetCarritoUsuario:'[event] reset Usuario',
    
    ViewActividad:'[event] view Actividad',
    currentActivity:'[event] Current activity',
    currentActivityEnd:'[event] Current activity end',
    evaluacionActividad:'[event] evaluacion actividad',
    calificacionCurso:'[event] calificacion curso',
    
    postsUsuarioLoaded:'[event] posts usuario loaded'
    
}