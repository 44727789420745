
import { UserOutlined } from '@ant-design/icons';
import { Col, Row, List, Input, Avatar, Skeleton, Pagination, Rate } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { valoracionesCursoUsuario } from '../../actions/usuarios';

export const ValoracionesCursoUsuario = ({_id}) => {
   
    const { TextArea } = Input;
    const dispatch = useDispatch();
    
    const [statusCarga, setStatusCarga] = useState({
        status:false,
        total:8
    });
    //const [total, setTotal] = useState(8);
    const [paginaActual, setpaginaActual] = useState(1);
    const {valoraciones} = useSelector(state => state.usuarios.valoracionesUsuario)
    useEffect(() => {
        dispatch(valoracionesCursoUsuario(_id,1,setStatusCarga))
     }, [dispatch,_id])

     const handleCambioPagina = (pagina) => {
        setStatusCarga(false)
        setpaginaActual(pagina)
        dispatch(valoracionesCursoUsuario(_id,pagina,setStatusCarga));
    }

    return (
        <div >
                {
                    (statusCarga.status===true) 
                    ?
                
                    <div>
                
                        <List
                        style={{margin:'0 0 10px 0'}}
                        bordered={true}
                        itemLayout="vertical"
                        size="large"
                        dataSource={valoraciones}
                        
                        renderItem={item => (
                            
                        <List.Item >
                            
                            <Row >
                                
                                <Col xs={24} s={24} md={24} lg={12} className="gutter-row"   >
            
                                    <List.Item.Meta
                                    avatar={<Avatar  size="large" src={item.usuario.img===null ? <Avatar icon={<UserOutlined />} size="large" /> : `${item.usuario.img}`} />}
                                    title={` De: ${item.usuario.nombre} ${item.usuario.apellidos}`}
                                    style={{height:10}}
                                   
                                    />
                                    <Row>
                                        <Col xs={24} s={24} md={24} lg={12} className="gutter-row" >
                                            <Rate allowHalf disabled value={Number(item.rate)} style={{marginLeft:'55px',marginBottom:'5px'}} />
                                        </Col>

                                    </Row>
                                   
 
                                </Col>
                                <Col xs={24} s={24} md={24} lg={12}  className="gutter-row"  >
                                    <List.Item.Meta
                                        title={` Publicado el: ${item.fecha_rate}`}
                                        style={{textAlign:'center'}}
                                    />
                                </Col>
                                
                                   
                            </Row>
                           
                
        
                            <Row>
                                <Col span={24}>
                                    <div style={{marginBottom: '10px'}}>
                                            <TextArea  rows={4} value={item.rate_comment} style={{resize:'none',fontSize:'20px',height:'100px',color:'grey'}}  readOnly />
                                    </div>
                                </Col>
                            </Row>
                            
                        </List.Item>
                        
                        )}
                        />
                    </div> 
                    :
                <Skeleton active avatar/>
                }
                 <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginBottom:'10px'}}>
                    <Pagination defaultCurrent={paginaActual} onChange={handleCambioPagina} pageSize={8} total={statusCarga.total} responsive={true} />
                </div>

              
        </div>
    )
}
