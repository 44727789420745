import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { Row, Button } from 'antd';

export const AdminFichaUsuario = ({visible, setVisible, data}) => {
    const {nombre, apellidos, email} = data;

    return (
        <>
            <Modal
                centered
                footer={[
                    <Button 
                        style={{backgroundColor: 'var(--primary)', marginLeft: '5px'}}
                        key="ok" 
                        type="primary" 
                        onClick={() => setVisible(false)}
                    >
                      Aceptar
                    </Button>
                  ]}
                visible={visible}
                destroyOnClose={true}
                closable={false}
                onOk={() => setVisible(false)}
                onCancel={() => setVisible(false)}
            >   
                 <h2 style={{color: '#var(--primary)', textAlign: 'center'}}>Administrador</h2>
                <Row>
                    <p> <strong>Nombre: </strong> {nombre}</p>
                </Row>
                <Row>
                    <p> <strong>Apellidos: </strong> {apellidos}</p>
                </Row>
                <Row>
                    <p> <strong>Email: </strong> {email}</p>
                </Row>
                
            </Modal>
      </>
    )
}
