import {  Button, Col, Row, Skeleton } from 'antd';
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router';
import { cursoBienvenida } from '../../actions/usuarios';

import cursoNoImage from '../../assets/nuevoCurso.jpg';

export const BienvenidaScreen = () => {
    const history=useHistory();
    const { _id }=useParams();
    
    const  { curso, status }= useSelector(state => state.usuarios.bienvenidaUsuario)
    const dispatch = useDispatch();

    useEffect(() => {
        window.scrollTo(0,0)
        dispatch(cursoBienvenida(_id));
     
    }, [dispatch,_id]);
    const handleCourse=()=>{
        history.push(`/user/make-course/${_id}`)
    }

    const handleReturn=()=>{
        history.goBack()
    }
    
    return (
                
        <div style={{backgroundColor:'white',padding:'2% 5%'}}>
            {
                (!status) ?  (
                    <div><Skeleton active avatar/></div>
                )
                :(
                       
                    <div>
                        <Row>
                            <Col xs={24} sm={24} s={24} lg={24} className="gutter-row">
                               <div style={{display:'block',borderRadius:'30px',border:'solid var(--primary)',backgroundColor:'#C9E68E'}}>
                                    <img alt='principal' src={(curso.img) ? curso.img : cursoNoImage} style={{marginLeft:'auto',marginRight:'auto',display:'block',width:'200px',border:'2px solid var(--primary)', marginTop: '20px'}}/>
                                    <h1 style={{textAlign:'center', fontSize: '40px'}}>{curso.nombre}</h1>
                                    <div style={{padding: '2em'}}>
                                        <p style={{textAlign:'center', fontSize: '25px'}}>¡Bienvenido al curso!</p>
                                        <p style={{textAlign:'justify', fontSize: '20px', marginBottom: '5px'}}>Estamos encantados de darte la bienvenida a esta experiencia educativa en línea. Queremos expresar nuestro más sincero agradecimiento por unirte a nosotros en este viaje de aprendizaje.</p>
                                        <p style={{textAlign:'justify', fontSize: '20px', marginBottom: '5px'}}>Iniciar un curso nuevo puede ser emocionante y, a veces, desafiante, pero recuerda que cada paso que tomes te acercará más a tus metas. Tu decisión de inscribirte en este curso muestra tu compromiso con tu desarrollo personal y profesional, ¡y eso es algo digno de elogio!</p>
                                        <p style={{textAlign:'justify', fontSize: '20px', marginBottom: '5px'}}>Estamos aquí para apoyarte en cada paso del camino. Nuestro equipo está comprometido a proporcionarte los recursos necesarios y el apoyo que necesitas para alcanzar el éxito en este curso.</p>
                                        <p style={{textAlign:'justify', fontSize: '20px', marginBottom: '5px'}}>Recuerda, cada módulo que completes te acercará más a tus objetivos. Mantén la motivación alta y aprovecha al máximo esta oportunidad de aprender y crecer.</p>
                                        <p style={{textAlign:'justify', fontSize: '20px', marginBottom: '5px'}}>¡Bienvenido y adelante hacia nuevas metas!</p>
                                    </div>
                                    <Button type="primary" onClick={handleCourse} style={{marginLeft:'auto',marginRight:'auto',marginBottom:'5px',display:'block',backgroundColor: 'var(--primary)', color: 'white', fontWeight: 'bold',width:'400px',border:'none',fontSize:'17px'}} >Continuar</Button>

                                    <Button type="primary" onClick={handleReturn} danger  style={{marginLeft:'auto',marginRight:'auto',marginBottom:'5px',display:'block', color: 'white', fontWeight: 'bold',width:'400px',border:'none',fontSize:'17px'}} >Regresar</Button>
                               </div>
                            </Col>
                        </Row>
                    </div>
                ) 
            }
            
        </div>
    )
}
