import React from 'react';

import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
  } from "react-router-dom";

import { Politicas } from '../components/publico/politicas/Politicas';
import { RecPassFormDocenteScreen } from '../components/auth/RecPassFormDocenteScreen';
import { RecPassSolDocenteScreen } from '../components/auth/RecPassSolDocenteScreen';

import { AdminRouter } from './admin/AdminRouter';
import { DocenteRouter } from './docente/DocenteRouter';
import { UsuariosRouter } from './usuario/UsuariosRouter';


export const AppRouter = () => {

    return ( 
        <>
            <Router>
                <Switch> 
                    <Route path="/politicas" component={Politicas} />
                    <Route path="/docente/auth/sol-password" component={RecPassSolDocenteScreen} />
                    <Route path="/docente/auth/rec-password/(token=:token)?" component={RecPassFormDocenteScreen} />
                    <Route path="/docente" component={DocenteRouter} />
                    <Route path="/admin" component={AdminRouter} />
                    <Route path="/" component={UsuariosRouter} />
                    <Redirect to="/" />
                </Switch>
            </Router>
        </>
    )

       
}
