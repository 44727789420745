import { UserOutlined } from '@ant-design/icons';
import { Card, Col, Pagination, Rate, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { cursosUsuario } from '../../actions/usuarios';
import portada from '../../assets/nuevoCurso.jpg';

export const AprendizajeUsuario = () => {
    const dispatch = useDispatch();
    const [cursosInfo, setCursosInfo] = useState({
        status: false,
        cursos: [],
        total: 8
    });
    const [pagina, setPagina] = useState(1);
    useEffect(() => {
        dispatch(cursosUsuario(setCursosInfo,pagina))
        
    }, [dispatch,setCursosInfo, pagina])
    const cambioPagina = (p) => {
        setPagina(p);
        dispatch(cursosUsuario( setCursosInfo, p));
    }
    return (
        <div  style={{padding:'2% 5%',backgroundColor:'white'}}>
            
                <h1 style={{textAlign:'center',fontSize:'30px'}}>Mi aprendizaje</h1>
           
            <Row gutter={[12, 12]}>
                {
                    (cursosInfo.status === true)
                        ?
                            cursosInfo.cursos.map ((curso) => {
                                return (
                                    <Col key={curso._id} className="gutter-row" xs={24} sm={12} md={12} lg={6} >
                                        
                                        <Link to={`/user/make-course/${curso._id}`}>
                                            <Card
                                                hoverable
                                                cover={<img style={{height: '170px'}} alt="Podcast" src={(curso.img) ? curso.img : portada} />}
                                            >  
                                                <Card.Grid hoverable={false} style={{width: '100%', height:'60px', boxShadow: 'none'}}>
                                                    <div className="card-titulo bold">{curso.nombre}</div>
                                                </Card.Grid>

                                                <Card.Grid hoverable={false} style={{width: '100%', boxShadow: 'none'}}>
                                                    <div style={{display: 'flex'}}>
                                                        <span><h3>{curso.rate}</h3></span>
                                                        <Rate disabled allowHalf defaultValue={parseFloat(curso.rate)} style={{fontSize: '14px', color: 'orange', margin: '0px 5px 0px 5px'}} />
                                                        <span>({curso.no_evaluaciones})</span>
                                                    </div>
                                                    <div>
                                                        <span style={{fontSize: '12px'}}>{`${curso.maestro.nombre} ${curso.maestro.apellidos}`}</span>
                                                    </div>
                                                    <div className="card-header">
                                                        <div><UserOutlined /> {curso.no_participantes}</div>
                                                       
                                                    </div>
                                                </Card.Grid>
                                                
                                                
                                            </Card>
                                        </Link>
                                    </Col>
                                )
                            })
                        :
                            ([0,1,2,3,4,5,6,7]).map(n => (
                                <Col key={n} className="gutter-row" xs={24} sm={12} md={12} lg={6} >
                                    <Skeleton paragraph={{rows: 8}} active />
                                </Col>
                            ))
                }
            </Row>
            <Row>
                <div style={{width: '100%', textAlign: 'center', marginTop: '15px'}}>
                    <Pagination onChange={cambioPagina} pageSize={8} total={cursosInfo.total} responsive={true} />
                </div>
            </Row>
        </div>
    )
}
