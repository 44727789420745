import React from 'react';
import Modal from 'antd/lib/modal/Modal';
import { defaultValidateMessages } from '../../helpers/validateMessages';
import TextArea from 'antd/lib/input/TextArea';
import { Button, Col, Form, Input, Row, Switch } from 'antd';
import { useDispatch } from 'react-redux';
import { crearTema, editarTema } from '../../actions/maestros';

export const ModalAddTemas = ({_id, temaValues, setTemaValues, initValues, setVis, vis, setTemas, setStatusCarga}) => {

    const dispatch = useDispatch();

    const handleCancelar = () => {
        setTemaValues(initValues);
        setVis(false);
    }

    const handleAceptar = (data) => {
        
        if(temaValues._id === ''){
            dispatch(crearTema(_id, data, setVis, setTemas));
        }else{
            dispatch(editarTema(_id, temaValues._id, data, setTemas, setVis, setStatusCarga));
        }
    }

    return (
        <>
           <Modal
                visible={vis}
                destroyOnClose={true}
                closable={false}
                footer={null}
            >
                {
                    (temaValues._id === '')
                        ? <h2>Crear Nuevo Tema</h2>
                        : <h2>Editar Tema</h2>
                }
                <Form 
                    onFinish={handleAceptar}
                    style={{paddingTop:'15px'}}
                    validateMessages={defaultValidateMessages}
                    initialValues={temaValues}
                >
                    <Form.Item
                        name="nombre"
                        rules={[
                            {
                                required: true
                            }
                        ]}
                    >
                        <Input placeholder='Nombre del tema' />
                    </Form.Item>

                    <Form.Item
                        name="descripcion"
                    >
                        <TextArea style={{ height: '100px',resize: 'none'}} placeholder='Agrega una descripción del tema' />
                    </Form.Item>

                    <Row>
                        <Col span={12}>
                            <Form.Item
                                label='Activar Quiz'
                                name='quiz'
                                valuePropName='checked'
                            >
                                <Switch />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label='Activar foro'
                                name='foro'
                                valuePropName='checked'
                            >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>

                    <div style={{display:'flex' ,width:'100%', justifyContent:'flex-end'}}>
                        <Form.Item>
                            <Button  onClick={() => handleCancelar()} >Cancelar</Button>
                        </Form.Item>
                        <Form.Item>
                                <Button htmlType="submit" style={{backgroundColor: 'var(--primary)', color: 'white' , marginLeft: '5px'}}>Aceptar</Button>
                        </Form.Item>
                    </div>

                </Form>
            </Modal> 
        </>
    )
}
