import React, { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getPreguntas, borrarPregunta } from '../../actions/maestros';
import { Pregunta } from './Pregunta';
import { Row, Col, Pagination } from 'antd';
import { useParams } from 'react-router-dom';

export const PreguntasScreen = () => {
    const {_id:id} = useParams();
    const dispatch = useDispatch();
    const [preguntas, setPreguntas] = useState({
        pregunta: [],
        total: 5
    });

    useEffect(() => {
        window.scrollTo(0,0);
        dispatch(getPreguntas(id, setPreguntas));
    }, [id, setPreguntas, dispatch]);

    const onChange = (pag) => {
        console.log(pag);
        dispatch(getPreguntas(id, setPreguntas, pag));
    }
    console.log(preguntas.total);

    return (
        <div style={{padding: '30px'}}>
            <Row>
                <Col span={16} offset={4} >
                    <h1 style={{textAlign: 'center'}}>Preguntas</h1>
                    <div>
                    {(Object.keys(preguntas).length > 0)
                    ? (
                       <div> 
                            {
                                preguntas.pregunta.map (pregunta =>(
                                    <Pregunta 
                                        key = {pregunta._id}
                                        info = {pregunta}
                                        eliminarPregunta = {() => {
                                            dispatch(borrarPregunta(id, pregunta._id, setPreguntas))
                                        }}
                                    />
                                ))
                            }
                            <Pagination 
                                simple
                                defaultPageSize={5} 
                                onChange={onChange} 
                                total={preguntas.total}
                                style={{display: 'flex', justifyContent: 'center'}}
                            >
                            </Pagination>
                        </div>
                     ) 
                    :
                        (<div className="pregunta-card">
                            <p className="preguntas-none">No se han hecho preguntas todavía</p>
                        </div>)
                    }
                    </div>
                </Col>
            </Row>
        </div>
    )
}
