import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, Link, useLocation } from 'react-router-dom';
import { Menu } from 'antd';
import Layout, { Header, Content } from 'antd/lib/layout/layout';
import Sider from 'antd/lib/layout/Sider';
import { MenuOutlined, LogoutOutlined, UserOutlined, HomeOutlined, TagOutlined, AppstoreOutlined, LineChartOutlined, IdcardOutlined, TeamOutlined } from '@ant-design/icons';
import { startLogout } from '../../actions/auth';

import { AdminHomeScreen } from '../../components/admin/AdminHomeScreen';
import { AdminUsuariosScreen } from '../../components/admin/AdminUsuariosScreen';
import { AdminCategoriesScreen } from '../../components/admin/AdminCategoriesScreen';
import { AdminAlumnosScreen } from '../../components/admin/AdminAlumnosScreen';
import { AdminAlumnoScreen } from '../../components/admin/AdminAlumnoScreen';
import { AdminMaestrosScreen } from '../../components/admin/AdminMaestrosScreen';
import { AdminMaestroScreen } from '../../components/admin/AdminMaestroScreen';
import { AdminCursosScreen } from '../../components/admin/AdminCursosScreen';
import { AdminCursoScreen } from '../../components/admin/AdminCursoScreen';
import { AdminEstadisticasScreen } from '../../components/admin/AdminEstadisticasScreen';
import { FooterAdmin } from '../../components/ui/FooterAdmin';

export const AdminPrivateRouter = () => {

    const location = useLocation();    
    const dispatch = useDispatch();
    const {nombre} = useSelector(state => state.auth);
    const [coll, setColl] = useState(false);

    const handleLogout = () => {
        dispatch(startLogout());
    }

    const {pathname} = location;

    useEffect(() => {
        window.scrollTo(0,0);
    }, []);

    return (
        <Layout>
            <Header
                style={{backgroundColor: '#fff'}}
                className="navbar-admin"
            >
                <div style={{display: 'flex', justifyContent: 'space-between', height: 'inherit', alignItems: 'center'}}>
                    <MenuOutlined 
                        className={(coll) ? 'icon-menu-navbar' : 'icon-menu-navbar-clicked'}
                        onClick={() => {setColl(!coll)}}
                    />
                    <div className="logout-navbar" onClick={handleLogout}>
                        <LogoutOutlined style={{color: 'var(--primary)'}}/>
                        <div style={{marginLeft: '10px'}}>Logout</div>
                    </div>
                    
                </div>
            </Header>

            <Layout className="layout-admin">
                <Sider 
                    className="sider-admin"
                    collapsible
                    collapsed={coll}
                    collapsedWidth={0}
                    trigger={null}
                    width={250}
                >
                    <div  className="usuario-container-sidebar">
                        <UserOutlined className="icon-user-sidebar"/>
                        <div className="bold titulo-sidebar"> {nombre} </div>
                        <div className="subtitulo-sidebar"> Admin </div>
                    </div>
                    <Menu mode="vertical" style={{borderRight: 'none'}} >
                        <Menu.Item 
                            key="1" 
                            icon={<HomeOutlined className="icon-menu-sidebar" />} 
                            className={(pathname ==='/admin/home') ? 'active-menu menu-item-sidebar' : 'menu-item-sidebar'}
                        >
                                <Link to='/admin/home'><span className="item">Inicio</span></Link>
                        </Menu.Item>
                        <Menu.Item 
                            key="2" 
                            icon={<UserOutlined className="icon-menu-sidebar" />} 
                            className={(pathname ==='/admin/usuarios') ? 'active-menu menu-item-sidebar' : 'menu-item-sidebar'}
                        >
                            <Link to='/admin/usuarios'><span className="item">Usuarios</span></Link>
                        </Menu.Item>
                        <Menu.Item 
                            key="3" 
                            icon={<TagOutlined className="icon-menu-sidebar" />} 
                            className={(pathname ==='/admin/categories') ? 'active-menu menu-item-sidebar' : 'menu-item-sidebar'}
                        >
                            <Link to='/admin/categories'><span className="item">Categorias</span></Link>
                        </Menu.Item>
                        <Menu.Item 
                            key="4" 
                            icon={<TeamOutlined className="icon-menu-sidebar" />} 
                            className={(pathname ==='/admin/alumnos') ? 'active-menu menu-item-sidebar' : 'menu-item-sidebar'}
                        >
                            <Link to='/admin/alumnos'><span className="item">Alumnos</span></Link>
                        </Menu.Item>
                        <Menu.Item 
                            key="5" 
                            icon={<IdcardOutlined className="icon-menu-sidebar" />} 
                            className={(pathname ==='/admin/Maestros') ? 'active-menu menu-item-sidebar' : 'menu-item-sidebar'}
                        >
                            <Link to='/admin/Maestros'><span className="item">Maestros</span></Link>
                        </Menu.Item>
                        <Menu.Item 
                            key="6" 
                            icon={<AppstoreOutlined className="icon-menu-sidebar" />} 
                            className={(pathname ==='/admin/cursos') ? 'active-menu menu-item-sidebar' : 'menu-item-sidebar'}
                        >
                            <Link to='/admin/cursos'><span className="item">Cursos</span></Link>
                        </Menu.Item>
                        <Menu.Item 
                            key="7" 
                            icon={<LineChartOutlined className="icon-menu-sidebar" />} 
                            className={(pathname ==='/admin/estadisticas') ? 'active-menu menu-item-sidebar' : 'menu-item-sidebar'}
                        >
                            <Link to='/admin/estadisticas'><span className="item">Estadísticas</span></Link>
                        </Menu.Item>
                    </Menu>
                </Sider>

                <Content className="admin-container">
                    {/* * * * * * * Router * * * * * * */}
                    <Switch>
                        <Route exact path="/admin/home" component={AdminHomeScreen} />
                        <Route exact path="/admin/usuarios" component={AdminUsuariosScreen} />
                        <Route exact path="/admin/categories" component={AdminCategoriesScreen} />
                        <Route exact path="/admin/alumnos" component={AdminAlumnosScreen} />
                        <Route exact path="/admin/alumno/:_id" component={AdminAlumnoScreen} />
                        <Route exact path="/admin/maestros" component={AdminMaestrosScreen} />
                        <Route exact path="/admin/maestro/:_id" component={AdminMaestroScreen} />
                        <Route exact path="/admin/cursos" component={AdminCursosScreen} />
                        <Route exact path="/admin/curso/:_id" component={AdminCursoScreen} />
                        <Route exact path="/admin/estadisticas" component={AdminEstadisticasScreen} />
                        <Redirect to="/admin/home" />
                    </Switch>
                    {/* * * * * * * Router * * * * * * */}
                    
                    <Layout>        
                        <FooterAdmin />
                    </Layout>
                </Content>
                
            </Layout>
        </Layout>
    )
}