import React, { useState } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import { DocenteHomeScreen } from '../../components/docente/DocenteHomeScreen';
import { PreguntasScreen } from '../../components/docente/PreguntasScreen';
import { DocenteIndexCursosScreen } from '../../components/docente/DocenteIndexCursosScreen';
import { VistaActividadScreen } from '../../components/docente/VistaActividadScreen';
import { Button, Col, Layout, Row, Menu } from 'antd';
import { CustomFooter } from '../../components/ui/CustomFooter';
import { FormOutlined, HomeOutlined, ImportOutlined, MenuOutlined, UserOutlined } from '@ant-design/icons';
import { startLogout } from '../../actions/auth';
import { useDispatch, useSelector } from 'react-redux';
import { LoadingScreen } from '../../components/ui/LoadingScreen';
import { ForoMaestroScreen } from '../../components/docente/ForoMaestroScreen';
import { DocenteProfileScreen } from '../../components/docente/DocenteProfileScreen';
import { DocenteCursoTabsScreen } from '../../components/docente/DocenteCursoTabsScreen';

export const DocentePrivateRouter = () => {

    const {checking} = useSelector(state => state.auth);

    const dispatch = useDispatch();

    const {Sider, Header, Content} = Layout;

    const [trigger, setTrigger] = useState(false);

    const handleLogout = () => {
        dispatch(startLogout());
    }

    if(checking){
        return(
            <LoadingScreen />
        )
    }

    return (
        <>
          <div style={{maxWidth: '100vw'}}>
          <Layout style={{minHeight: '100vh', maxWidth: '100vw'}}>
                <Header
                    style={{
                        backgroundColor: 'white',
                        borderBottom: '5px solid var(--primary)',
                    }}
                >
                    <Row>
                        <Col span={2}>
                            <h3>Navbar</h3>
                        </Col>
                        <Col span={2}>
                            <MenuOutlined onClick={() => setTrigger(!trigger)}/>
                        </Col>
                        <Col span={19}>
                        
                        </Col>
                        <Col span={1}>
                            <Button type='link' style={{color: 'var(--primary)'}} onClick={handleLogout}><ImportOutlined style={{color: 'var(--primary)'}}/>Logout</Button>
                        </Col>
                    </Row>
                    
                </Header>

                <Layout style={{backgroundColor: 'white'}}>
                <Sider
                    collapsible
                    defaultCollapsed
                    collapsed={trigger}
                    trigger={null}
                    width='130px'
                    style={{
                        backgroundColor: 'white',
                        boxShadow: '0 0 5px #dedede'
                    }}
                >
                    <Menu >
                        <Menu.Item  key={1} title='Inicio' icon={<HomeOutlined />} className='docente-sider-menu-item'>
                           <Link to='/docente/home' className='docente-sider-link'>
                                Inicio
                           </Link>
                        </Menu.Item>

                        <Menu.Item key={2} title='Cursos' icon={<FormOutlined />} className='docente-sider-menu-item'>
                            <Link to='/docente/courses' className='docente-sider-link'>
                                Mis Cursos
                            </Link>
                        </Menu.Item>

                        <Menu.Item key={3} title='Perfil' icon={<UserOutlined />} className='docente-sider-menu-item'>
                            <Link to='/docente/profile' className='docente-sider-link'>
                                Mi Perfil
                            </Link>
                        </Menu.Item>
                    </Menu>
                </Sider>

                    <Layout>
                        <Content className='docente-container'>
                            <Switch>
                                <Route exact path="/docente/home" component={DocenteHomeScreen} />
                                <Route exact path="/docente/courses" component={DocenteIndexCursosScreen} />
                                <Route exact path="/docente/profile" component={DocenteProfileScreen} />
                                <Route path="/docente/courses/:_id" component={DocenteCursoTabsScreen} />
                                <Route path="/docente/actividad/:_id" component={VistaActividadScreen} />
                                <Route exact path="/docente/preguntas/:_id" component={PreguntasScreen} />
                                <Route exact path="/docente/foro/:_id" component={ForoMaestroScreen} />
                                <Redirect to="/docente/home" />
                            </Switch>
                        </Content>
                    </Layout>
                
                </Layout>
            </Layout>
            <CustomFooter />
            
          </div>
        </>
    )
}
