import React, { useState, useEffect } from 'react';
import { Card, Col, Row, Button, Carousel, Rate } from 'antd';
import {UserOutlined} from '@ant-design/icons';
import { useWindowSize } from '../../helpers/useWindowDimensions';
import { Skeleton } from 'antd';
import { loadCategoriasUsuario, loadCursosUsuario, getMaestros } from '../../actions/usuarios';
import { useDispatch } from 'react-redux';

import portada from '../../assets/nuevoCurso.jpg';
import { Link, useHistory } from 'react-router-dom';

const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      type="button"
    >
    </button>
);

const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <button
      {...props}
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
      type="button"
    >
    </button>
);

export const HomeScreen = () => {
    const history=useHistory()

    const dispatch = useDispatch();
    const { width } = useWindowSize();
    // const numbers = ;
    const [slidersCateg, setSlidersCateg] = useState(7);

    const [categorias, setCategorias] = useState([]);

    const [maestros, setMaestros] = useState({
        maestros: [],
        total: 8
    });
    // eslint-disable-next-line no-unused-vars
    const [params, setParams] = useState({
        pagina: 1,
        search: '',
        filter: 0,
        filter_key: ''
    });
    const [loaded, setLoaded] = useState(true);

    const handleRegistrarse = () => {

    }

    const [cursosInfo, setCursosInfo] = useState({
        status: false,
        cursos: [],
        total: 8
    });

    /*Número de slides categorias*/
    useEffect(() => {
        if(width < 500){
            setSlidersCateg(1);
        }else if(width > 500 && width < 660){
            setSlidersCateg(2)
        }else if(width > 660 && width < 930){
            setSlidersCateg(3)
        }else if(width > 930 && width < 1240){
            setSlidersCateg(4)
        }else if(width > 1240){
            setSlidersCateg(5)
        }

        dispatch(loadCursosUsuario(1,'',setCursosInfo));
        dispatch(loadCategoriasUsuario(setCategorias));
        dispatch(getMaestros(setMaestros, params, setLoaded));

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width, dispatch]);

    const HandleCategories=()=>{
        history.push(`/categories`);
    }

    const handleVerMaestros = () => {
        history.push(`/maestros`);
    }

    return (
        <>
            <div>
                {/*Banner 1*/}
                <Row>
                    <div className="banner-1">
                        <Carousel
                            className="carousel-1"
                            autoplay={true}
                            dots={false}
                        >   
                            <div>
                                <div
                                className="carousel-1-img"
                                style={{
                                    backgroundImage: 'url(https://images.pexels.com/photos/3791985/pexels-photo-3791985.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)'
                                }}
                                ></div>
                            </div>
                            <div>
                                <div
                                className="carousel-1-img"
                                style={{
                                    backgroundImage: 'url(https://images.pexels.com/photos/4778424/pexels-photo-4778424.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)',
                                }}
                                ></div>
                            </div>
                            <div>
                                <div
                                className="carousel-1-img"
                                style={{
                                    backgroundImage: 'url(https://images.pexels.com/photos/7014916/pexels-photo-7014916.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)',
                                }}
                                ></div>
                            </div>
                            <div>
                                <div
                                className="carousel-1-img"
                                style={{
                                    backgroundImage: 'url(https://images.pexels.com/photos/4778424/pexels-photo-4778424.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940)',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'none',
                                    width: '100vw',
                                    height: '100vh'
                                }}
                                ></div>
                            </div>
                        </Carousel>
                        <div className='banner-1-content'>
                            <h1 className="banner-1-titulo text-center">The best theme </h1>
                            <h1 className="banner-1-subtitulo text-center">for education</h1>
                            <div className='centrar'>
                                <Button
                                    className="btn-home-registrate" 
                                    onClick={handleRegistrarse}
                                >
                                    Registrate
                                </Button>
                            </div>
                        </div>
                    </div>
                </Row>
            </div>
            <div style={{padding: '5vh 12vw 0 12vw'}}>
                {/*Carousel categorias*/}
                <div>
                    <Carousel
                        dots={false}
                        slidesToScroll={1}
                        slidesToShow={slidersCateg}
                        arrows={true}
                        prevArrow={<SlickArrowLeft/>}
                        nextArrow={<SlickArrowRight/>}
                    >
                        {
                            categorias.map( categoria => (
                                <Link key={categoria._id} to={`/courses/categories/${categoria._id}`}>
                                    <div  className="home-categ-card">
                                        <div className={"auth-center home-categ-card-nombre"}>
                                            {categoria.nombre}
                                        </div>
                                    </div> 
                                </Link>
                            ))
                        }
                    </Carousel>
                </div>

                <div style={{display:'flex',alignItems:'center',justifyContent:'center',marginTop:'15px'}}>
                    <Button style={{backgroundColor:'var(--primary)',border:'none',width:'200px',color:'white',fontSize:'20px',padding:'2px'}} onClick={HandleCategories}>Ver mas categorias</Button>
                </div>

                <Row>
                    <h1 className="header-1 bold">Cursos que te podrían interesar</h1>
                    <Col span={24} style={{textAlign: 'end'}}>
                        <Link to={'/courses'}>
                            <h3>Ver todos los cursos...</h3>
                        </Link>
                    </Col>
                </Row>
                <Row gutter={[12, 12]}>
                    
                    {
                        (cursosInfo.status === true)
                            ?
                                cursosInfo.cursos.map ((curso) => {
                                    return (
                                        <Col key={curso._id} className="gutter-row" xs={24} sm={12} md={12} lg={6} >
                                           
                                            <Link to={`/courses/${curso._id}`}>
                                                    <Card
                                                        hoverable
                                                        cover={<img style={{height: '170px'}} alt="Podcast" src={(curso.img) ? curso.img : portada} />}
                                                    >  
                                                        <Card.Grid hoverable={false} style={{width: '100%', height:'60px', boxShadow: 'none'}}>
                                                            <div className="card-titulo bold">{curso.nombre}</div>
                                                        </Card.Grid>

                                                        <Card.Grid hoverable={false} style={{width: '100%', boxShadow: 'none'}}>
                                                            <div style={{display: 'flex'}}>
                                                                <span><h3>{curso.rate}</h3></span>
                                                                <Rate disabled allowHalf defaultValue={parseFloat(curso.rate)} style={{fontSize: '14px', color: 'orange', margin: '0px 5px 0px 5px'}} />
                                                                <span>({curso.no_evaluaciones})</span>
                                                            </div>
                                                            <div>
                                                                <span style={{fontSize: '12px'}}>{`${curso.maestro.nombre} ${curso.maestro.apellidos}`}</span>
                                                            </div>
                                                            <div className="card-header">
                                                                <div><UserOutlined /> {curso.no_participantes}</div>
                                                                <div style={{color: 'green'}}>{(curso.precio === '0') ? "Gratis" : '$'+curso.precio}</div>
                                                            </div>
                                                        </Card.Grid>
                                                        
                                                        
                                                    </Card>
                                            </Link>
                                        </Col>
                                    )
                                })
                            :
                                ([0,1,2,3,4,5,6,7]).map(n => (
                                    <Col key={n} className="gutter-row" xs={24} sm={12} md={12} lg={6} >
                                        <Skeleton paragraph={{rows: 8}} active />
                                    </Col>
                                ))
                    }
                </Row>
                
                <Row>
                    <h1 className="header-1 bold">Maestros</h1>
                </Row>
                <Carousel
                    dots= {false}
                    slidesToScroll= {1}
                    slidesToShow={4}
                    arrows = {false}
                    autoplay
                >
                    {   
                    (!loaded) 
                        ? ( 
                            (maestros.maestros.length > 0) 
                            ? (maestros.maestros.map((maestro) => {
                                return (
                                    <Link key={maestro._id} to={"/maestro/" + maestro._id}>
                                        <Card key={maestro._id} style={{margin: '20px', backgroundColor: '#ededed'}}>
                                            <div className="maestro-card-home">
                                                {
                                                    (maestro.img)
                                                    ?(<img className="maestro-card-img" src={maestro.img} alt=""/> )
                                                    :(<div className="maestro-card-iniciales">{maestro.iniciales}</div>)
                                                }
                                                <div>
                                                    <div className="maestro-card-nombre-home">{maestro.nombre}</div>
                                                    <div className="maestro-card-profesion">{maestro.profesion}</div>
                                                </div>
                                            </div>
                                        </Card>
                                    </Link>
                                )
                            }))
                            :(<div className="no-disponible">No hay maestros por mostrar</div>)
                        )
                        : (       
                            [0,1,2,3,4,5,6,7].map(n => (     
                                <Card key={n} style={{backgroundColor: '#ededed', margin: '20px'}}>
                                    <div className="maestro-card-skeleton" style={{display: 'flex', flexDirection: 'row'}}>
                                        <Skeleton.Avatar active className="maestro-skeleton-img" shape="circle"/>
                                        <Skeleton title={false} paragraph={{ rows: 2, width: '100%' }} />
                                    </div>
                                </Card>
                            ))
                        )
                    }
                </Carousel> 

                <Row>
                    <Button style={{backgroundColor: 'transparent',border:'none',width:'200px',color:'var(--primary)',fontSize:'20px',padding:'2px', margin: '10px auto'}} onClick={handleVerMaestros}>
                        Ver todos los Maestros
                    </Button>
                </Row>
            </div>
        </>
    )
}
