import { Button, Col, Collapse, InputNumber, Popconfirm, Row } from 'antd';
import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import { editarPreguntasMinimas, eliminarPregunta } from '../../actions/maestros';
import { CheckOutlined, CloseOutlined, DeleteOutlined, EditOutlined, FileDoneOutlined } from '@ant-design/icons';
import { ModalQuiz } from './ModalQuiz';
import TextArea from 'antd/lib/input/TextArea';

export const DocenteQuizScreen = ({actividad, statusCurso, setEditado, updateActividadData, setUpdateActividadData}) => {
    const dispatch = useDispatch();
    const { Panel } = Collapse;

    const [vis, setVis] = useState(false);
    const [quiz, setQuiz] = useState(actividad);
    const initPregunta = {
        id_pregunta: '',
        pregunta: ''
    };

    const [respActual, setRespActual] = useState([]);
    const [preguntaActual, setPreguntaActual] = useState(initPregunta)

    //Acciones de preguntas
    const handleAddPregunta = () => {
        setPreguntaActual({ ...initPregunta });
        setRespActual([]);
        setVis(true);
    }
    const handleEditarPregunta = (preguntaId, pregunta, respuestas) => {
        setPreguntaActual({
            id_pregunta: preguntaId,
            pregunta: pregunta,
        });
        setRespActual(respuestas);
        setVis(true);
    }
    const handleEliminarPregunta = (id_pregunta) => {
        dispatch(eliminarPregunta(quiz._id, id_pregunta, setQuiz));
    }
    const handleEditarPreguntasMinimas = () => {
        const value = document.getElementById('preguntasMinimas').value;
        const preguntas_minimas = parseInt(value);
        
        dispatch(editarPreguntasMinimas(quiz._id, preguntas_minimas));
    }

    const genConfigs = (preguntaId, pregunta, respuestas) => {

        const cantResp = `${respuestas.length} respuestas`
        return(
            <div style={{display: 'flex'}}>
                <div style={{marginRight: '10pxu'}}>
                    {cantResp}
                </div>
                <div>
                    <EditOutlined 
                        style={{fontSize: '17px', marginRight:'10px'}} 
                        onClick={event => {
                            handleEditarPregunta(preguntaId, pregunta, respuestas);
                            event.stopPropagation();
                        }}
                    />
                </div>
                <div>
                    <Popconfirm 
                        placement='topRight' 
                        title={'¿Seguro que quieres eliminar esta pregunta?'} 
                        okText='Si, eliminar' 
                        cancelText='Cancelar' 
                        onConfirm={event =>{
                            handleEliminarPregunta(preguntaId);
                            event.stopPropagation();
                        }}
                        onCancel={event => {
                            event.stopPropagation();
                        }}
                    >
                        <DeleteOutlined 
                            style={{fontSize: '17px'}}
                            onClick={event => {
                                event.stopPropagation();
                            }}
                        />
                    </Popconfirm>
                </div>
            </div>
        )
    }

    const handleChangeTitle = ({  target }) => {
        setUpdateActividadData({
            ...updateActividadData,
            titulo: target.value
        });
        setEditado(true);
    }

    const handleChangeDescripcion = ({  target }) => {
        setUpdateActividadData({
            ...updateActividadData,
            descripcion: target.value
        });
        setEditado(true);
    }

    //Si está productivo
    if(quiz.curso.status === 2){
        return (
            <>
            <div style={{backgroundColor: 'white', padding: '10px 30px'}}>
                <Row gutter={[36,24]}>
                    <Col span={24}>
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <div style={{fontSize: '30px', color: '#80837C'}}>{quiz.nombre}</div>
                        </div> 
                    </Col>

                    <Col span={24}>
                        <div style={{width: '100%', color: '#80837C'}}>
                            {quiz.descripcion}
                        </div>
                    </Col>
                </Row>
                <Row style={{marginTop: '30px'}}>
                    <h4>Contenido</h4>
                    <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <Collapse
                                destroyInactivePanel
                                expandIconPosition='left'
                                accordion
                            >
                            {
                                quiz.data.preguntas.map(pregunta => (
                                    <Panel
                                        key={pregunta._id}
                                        header={pregunta.pregunta}
                                        extra={(statusCurso !== 2) && (genConfigs(pregunta._id, pregunta.pregunta, pregunta.respuestas))}
                                    >
                                        <Row style={{marginLeft: '10px'}}>
                                        {
                                            pregunta.respuestas.map(respuesta => (
                                                <div key={respuesta._id} style={{width: '100%'}}>
                                                    <Col style={{margin: '5px auto 5px auto'}} span={24}>
                                                        <Row>
                                                            <Col span={23}>
                                                                {respuesta.respuesta}
                                                            </Col>
                                                            <Col span={1}>
                                                            {(respuesta.correcta === true) ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} /> }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            ))
                                        }
                                        </Row>
                                    </Panel>
                                ))
                            }
                            </Collapse>
                        </Col>
                    </Row>
                    </Col>
                </Row>
            </div>
            </>
        )
    }
    //Si es ultimo quiz
    else if(quiz.end === 1 ){
        return(
        <>
            <div style={{backgroundColor: 'white', padding: '10px 30px'}}>
                <Row>
                    <Col span={24}>
                        <div style={{width: '100%', textAlign: 'center'}}>
                            <div style={{fontSize: '30px', color: '#80837C'}}>{quiz.nombre}</div>
                        </div> 
                    </Col>
                </Row>
                <Row>
                    <h4>Contenido</h4>
                    <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <Collapse
                                destroyInactivePanel
                                expandIconPosition='left'
                                accordion
                            >
                            {
                                quiz.data.preguntas.map(pregunta => (
                                    <Panel
                                        key={pregunta._id}
                                        header={pregunta.pregunta}
                                        extra={(statusCurso !== 2) && (genConfigs(pregunta._id, pregunta.pregunta, pregunta.respuestas))}
                                    >
                                        <Row style={{marginLeft: '10px'}}>
                                        {
                                            pregunta.respuestas.map(respuesta => (
                                                <div key={respuesta._id} style={{width: '100%'}}>
                                                    <Col style={{margin: '5px auto 5px auto'}} span={24}>
                                                        <Row>
                                                            <Col span={23}>
                                                                {respuesta.respuesta}
                                                            </Col>
                                                            <Col span={1}>
                                                            {(respuesta.correcta === true) ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} /> }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            ))
                                        }
                                        </Row>
                                    </Panel>
                                ))
                            }
                            </Collapse>
                            <div style={{textAlign: 'center'}}>
                                <Button className='btn-link-agregar' type='link' style={{color: 'grey'}} onClick={() => {handleAddPregunta()}} >+ Agregar Pregunta</Button>
                            </div>
                            <div style={{alignItems: 'end', display: 'flex', margin: '30px 5% 30px 5%'}}>
                            <div style={{marginLeft: 'auto'}}>
                                    <FileDoneOutlined style={{fontSize: '17px', marginRight:'5px', color: 'var(--primary)'}}/>
                                    Preguntas Minimas
                                    <InputNumber 
                                        id='preguntasMinimas'
                                        style={{borderBottom: '1px solid var(--primary)', width: '50px', marginLeft: '5px'}} 
                                        bordered={false} 
                                        min={0} 
                                        // max={quiz.preguntas.length} 
                                        defaultValue={quiz.data.preguntas_minimas} 
                                        size={'small'}
                                        onBlur={handleEditarPreguntasMinimas}
                                    />
                            </div>
                        </div>
                        </Col>
                    </Row>
                    </Col>
                </Row>
            </div>

            <ModalQuiz 
                _id={quiz._id}
                vis={vis} 
                setVis={setVis}
                setQuiz={setQuiz}
                preguntaActual={preguntaActual}
                setRespActual={setRespActual}
                respActual={respActual}
            />
        </>
        )
    }
    //Si está en desarrollo
    else{
        return (
            <>
            <div style={{backgroundColor: 'white', padding: '10px'}}>
                <Row>
                    <Col span={24}>
                        <h4>Titulo</h4>
                        <TextArea 
                            id="nombre" 
                            autoSize={true} 
                            className="input-titulo" 
                            style={{display: 'block', color: '#80837C'}} 
                            placeholder='Titulo de actividad'
                            value={updateActividadData.titulo} 
                            onChange={handleChangeTitle}
                        />
                    </Col>

                    <Col span={24} style={{marginTop: '20px'}}>
                        <h4>Descripción</h4>
                        <TextArea 
                            id='descripcion'  
                            style={{height: '200px', resize: 'none',color:'#80837C'}}  
                            autoSize={false} 
                            placeholder='Descripción de actividad'
                            value={updateActividadData.descripcion}
                            onChange={handleChangeDescripcion}
                        />
                    </Col>
                </Row>
                <Row style={{marginTop: '20px'}}>
                    <h4>Contenido</h4>
                    <Col span={24}>
                    <Row>
                        <Col span={24}>
                            <Collapse
                                destroyInactivePanel
                                expandIconPosition='left'
                                accordion
                            >
                            {
                                quiz.data.preguntas.map(pregunta => (
                                    <Panel
                                        key={pregunta._id}
                                        header={pregunta.pregunta}
                                        extra={(statusCurso !== 2) && (genConfigs(pregunta._id, pregunta.pregunta, pregunta.respuestas))}
                                    >
                                        <Row style={{marginLeft: '10px'}}>
                                        {
                                            pregunta.respuestas.map(respuesta => (
                                                <div key={respuesta._id} style={{width: '100%'}}>
                                                    <Col style={{margin: '5px auto 5px auto'}} span={24}>
                                                        <Row>
                                                            <Col span={23}>
                                                                {respuesta.respuesta}
                                                            </Col>
                                                            <Col span={1}>
                                                            {(respuesta.correcta === true) ? <CheckOutlined style={{color: 'green'}} /> : <CloseOutlined style={{color: 'red'}} /> }
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                </div>
                                            ))
                                        }
                                        </Row>
                                    </Panel>
                                ))
                            }
                            </Collapse>
                            <div style={{textAlign: 'center'}}>
                                <Button className='btn-link-agregar' type='link' style={{color: 'grey'}} onClick={() => {handleAddPregunta()}} >+ Agregar Pregunta</Button>
                            </div>
                            <div style={{alignItems: 'end', display: 'flex', margin: '30px 5% 30px 5%'}}>
                            <div style={{marginLeft: 'auto'}}>
                                    <FileDoneOutlined style={{fontSize: '17px', marginRight:'5px', color: 'var(--primary)'}}/>
                                    Preguntas Minimas
                                    <InputNumber 
                                        id='preguntasMinimas'
                                        style={{borderBottom: '1px solid var(--primary)', width: '50px', marginLeft: '5px'}} 
                                        bordered={false} 
                                        min={0} 
                                        // max={quiz.preguntas.length} 
                                        defaultValue={quiz.data.preguntas_minimas} 
                                        size={'small'}
                                        onBlur={handleEditarPreguntasMinimas}
                                    />
                            </div>
                        </div>
                        </Col>
                    </Row>
                    </Col>
                </Row>
            </div>

            { vis && <ModalQuiz 
                _id={quiz._id}
                vis={vis} 
                setVis={setVis}
                setQuiz={setQuiz}
                preguntaActual={preguntaActual}
                setRespActual={setRespActual}
                respActual={respActual}
            /> }
        </>
        )
    }
}