import React, { useEffect, useState } from 'react'
import { List, Avatar, Row, Col, Button, Empty } from 'antd';
import { useDispatch, useSelector} from 'react-redux';
import { agregarCarrito, pagarCarrito } from '../../actions/usuarios';
import portada from '../../assets/nuevoCurso.jpg';
import carrito2 from '../../assets/carrito-de-compras.png';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router';
import { UserOutlined } from '@ant-design/icons';

export const PagarCarritoScreen = () => {
    const history=useHistory()
    const {carritoUsuario:car} = useSelector(state => state.usuarios)
    
    const dispatch = useDispatch();
    useEffect(() => {
        window.scrollTo(0,0)
          dispatch(agregarCarrito());
    }, [dispatch])
    const [statusPago, setStatusPago] = useState({
        status:false,
    })

    const handlePagar=(data,_id)=>{

        dispatch(pagarCarrito(data,setStatusPago))
            if (car.items.length>1){
                history.push('/user/mi_aprendizaje')
                
            }else{
                history.push(`/user/bienvenida/${_id}`)
            }
            
            if (statusPago.status){
                Swal.fire({
                    icon: 'success',
                    title: 'Pago exitoso',
                    text: 'Su transaccion se ha realizado de manera adecuada',
                })                
            }
        
    }

    const handleGoHome=()=>{
        history.push('/user/home');
    }

    
    return (
        <div style={{padding:'2% 5%',backgroundColor:'white'}}>
            {
                (car.total>0) ? (
                    <>
                    <Row style={{backgroundColor:'#EDF1E7',padding:'1% 5%'}}>
                        <Col xs={24} s={24} lg={24}>
                            <h1 style={{textAlign:'center'}}>Carrito de compras </h1>
                        </Col>
                       
                    </Row>
                    <List
                        itemLayout="vertical"
                        size="small"
                        dataSource={car.items}
                        renderItem={item => (
                        <List.Item
                        
                            extra={
                            <img
                                width={272}
                                alt="logo"
                                src={(item.img===null) ? portada : item.img }
                            />
                            }
                        >
                            <List.Item.Meta
                            avatar={<Avatar src={item.maestro.img===null ? 'error' : `${item.maestro.img}`} fallback={<UserOutlined />}/>}
                            title={` Impartido por: ${item.maestro.nombre} ${item.maestro.apellidos}`}
                            
                            />
                           <h2 style={{color:'var(--primary)',display:'flex'}}>Nombre del curso: <span style={{color:'black',marginLeft:'1px'}}> {item.nombre}</span></h2>
                            <h2 style={{color:'var(--primary)'}}>Precio del curso: <span style={{color:'black',marginLeft:'1px'}}>{`$${item.precio}`} </span></h2>
                        </List.Item>
                      
                        )}
                    />
                    <Row>
                    <Col xs={24} sm={12}></Col>
                    <Col xs={24} sm={12}>
                   
                    <Button type="primary" onClick={()=>handlePagar(car.data,car.items[0]._id)} block style={{backgroundColor:'var(--primary)',border:'none',fontSize:'1.1rem',textAlign:'center',padding:'auto'}}>Pagar {`$${car.total}`} </Button>
                                  
                       
                    </Col>
                    </Row>
                    </>
                    
                ) 
                :(
                    <div style={{textAlign:'center'}}>
                        <h1 style={{fontSize:'30px'}}>Tu cesta está vacía. ¡Sigue comprando para encontrar un curso!</h1>
                        <Empty image={carrito2} description={false}/>
                        <Button type="primary" onClick={handleGoHome} style={{backgroundColor:'var(--primary)',border:'none',width:'200px'}}><span style={{fontSize:'15px'}}>Seguir comprando</span></Button>

                    </div>
                )
            }
            
            
            
        </div>
    )
}
