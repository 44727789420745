import React, { useEffect } from 'react'
import { Row, Col, Switch, Input, Select, Image, Button, Radio, Space, message, Tag, Form, InputNumber } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { editarPortada, editarInfoGeneralCurso } from '../../actions/maestros';
import { UsergroupAddOutlined, GiftOutlined, QuestionCircleOutlined, DollarOutlined, ClockCircleOutlined, TagsOutlined } from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2';
import nuevoCurso from '../../assets/nuevoCurso.jpg';

export const TabInformacionCurso = ({_id, curso, setCurso, indexCategorias}) => {   
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const cursoCategorias = [];
    const cursoCategoriasId = [];
    const options = [];

  
    if (curso.categorias) {
        curso.categorias.forEach(categoria => {
            cursoCategorias.push(categoria.nombre);
            cursoCategoriasId.push(categoria._id);
        })
    }


  if(indexCategorias){
    indexCategorias.forEach(categoria => {
      options.push({ label: categoria.nombre, value: categoria._id });
    })
  }

  const handleCargarImagen = () => {
      document.getElementById('img_input').click();
  }

  const handleEditarPortada = (e) => {
      if (e.target.files && e.target.files[0]) {
          const file = e.target.files[0];

          //Validar extensión de imagen
          const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
          if (!allowedExtensions.exec(file['name'])) {
              message.error('Por favor seleccione un archivo tipo .jpeg .jpg o .png');
          } else {
              dispatch(editarPortada(_id, file, setCurso));
          }
      }
  }

  const handleGuardar = async (values) => {
    dispatch(editarInfoGeneralCurso(_id, values, curso.status, setCurso));
  }
  
  const handleDesactivarPrecio = (value) => {
    // if (value === true) {
    //     // document.getElementById('precio').value = '0';
    //     setCurso({
    //         ...curso,
    //         gratis: true
    //     })
    // } else {
    //     setCurso({
    //         ...curso,
    //         gratis: false
    //     })
    // }
  }

  const handleChangeStatus = (e) => {
    if (e.target.value === 2) {
        Swal.fire({
            title: 'Cambio a Productivo',
            text: 'Al cambiar el curso a productivo limitarás su edición, además estará disponible para los usuarios',
            confirmButtonText: 'Entiendo',
            confirmButtonColor: 'var(--primary)',
        })
    }
  }

  const tagRender = (props) => {
    const { label, onClose } = props;
    const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    };

    return (
        <Tag
            color={'var(--primary)'}
            onMouseDown={onPreventMouseDown}
            closable={true}
            style={{ marginRight: 3, marginBottom: 3 }}
            onClose={() => {
                onClose()
            }}
        >
            {label}
        </Tag>
    );
  }

  useEffect(() => {
    form.setFieldsValue({
        'nombre': curso.nombre,
        'descripcion': curso.descripcion,
        'comunidad': curso.comunidad,
        'gratis': curso.gratis,
        'duracion': curso.duracion,
        'preguntasRespuestas': curso.preguntas_y_respuestas,
        'estado': curso.status
    });
  })

  return (
    <div style={{ padding: '30px', backgroundColor: '#fff' }}>
        <Form
            layout='vertical'
            form={form}
            initialValues={{
                "categorias": cursoCategoriasId,
                "gratis": curso.gratis
            }}
            onFinish={(values) => handleGuardar(values)}
        >
            <Row gutter={12} justify='space-around'>
                <Col span={14} style={{paddingLeft: '30px'}}>
                    <Form.Item label='Titulo del curso' name='nombre'>
                        <TextArea
                            readOnly={(curso.status) && true}
                            style={{ display: 'block' }} 
                            placeholder="Titulo del curso" 
                            autoSize={true} 
                            className="input-titulo" 
                        />
                    </Form.Item>
                    <Form.Item label='Descripción del curso' name='descripcion'>
                        <TextArea 
                            readOnly={(curso.status) && true}
                            style={{ display: 'block' }} 
                            id="desc" 
                            className="input-desc" 
                            autoSize={false} 
                            placeholder='Descripción del curso' 
                        />
                    </Form.Item>
                    
                    <Row>
                        <Col span={12}>
                            {
                                (curso.status !== 2) &&
                                <>
                                    <div className='info-item-docente'>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ color: 'var(--primary)' }}><UsergroupAddOutlined /></div>
                                            <div style={{ marginLeft: '10px' }}>Comunidad</div>
                                        </div>
                                        <Form.Item
                                            name='comunidad'
                                            valuePropName='checked'
                                            style={{ margin: '0' }}
                                        >
                                            <Switch 
                                                id="comunidad" 
                                                size="small" 
                                            />
                                        </Form.Item>
                                    </div>
                                </>
                            }

                            <div className='info-item-docente'>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ color: 'var(--primary)' }}><GiftOutlined /></div>
                                    <div style={{ marginLeft: '10px' }}>Gratis</div>
                                </div>
                                <Form.Item
                                    name='gratis'
                                    valuePropName='checked'
                                    style={{ margin: '0' }}
                                >
                                    <Switch 
                                        disabled={true}
                                        id='gratis'
                                        onChange={handleDesactivarPrecio} size="small" />
                                </Form.Item>
                            </div>

                            <div className='info-item-docente'>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ color: 'var(--primary)' }}><DollarOutlined /></div>
                                    <div style={{ marginLeft: '10px' }}>Precio</div>
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'end' }}>
                                    <Form.Item name='precio' style={{margin: '0px'}}>
                                        <InputNumber 
                                            min={1}
                                            formatter={value => `$ ${curso.precio}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} 
                                            style={{
                                                color: (curso.gratis) && 'grey'
                                            }}
                                            disabled={curso.gratis}
                                        />
                                    </Form.Item>
                                </div>
                            </div>

                            {
                                (curso.status !== 2) &&
                                <>
                                    <div className='info-item-docente'>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ color: 'var(--primary)' }}><QuestionCircleOutlined /></div>
                                            <div style={{ marginLeft: '10px' }}>Preguntas y respuestas</div>
                                        </div>
                                        <Form.Item
                                            name='preguntasRespuestas'
                                            valuePropName='checked'
                                            style={{ margin: '0' }}
                                        >
                                            <Switch 
                                                size="small" 
                                            />
                                        </Form.Item>
                                    </div>
                                </>
                            }

                            <div className='info-item-docente'>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ color: 'var(--primary)' }}><ClockCircleOutlined /></div>
                                    <div style={{ marginLeft: '10px' }}>Duración</div>
                                </div>
                                <Form.Item name='duracion' style={{margin: '0px'}}>
                                    <Input
                                        id='duracion'
                                        size="small"
                                        style={{ borderBottom: '1px solid var(--primary)', maxWidth: '90px' }}
                                        bordered={false}
                                    />
                                </Form.Item>
                            </div>

                            <div style={{ marginTop: '15px' }}>
                                <div style={{ display: 'flex' }}>
                                    <div style={{ color: 'var(--primary)' }}><TagsOutlined /></div>
                                    <div style={{ marginLeft: '10px' }}>Categorias</div>
                                </div>
                                <Form.Item name='categorias'>
                                <Select
                                    size="small"
                                    bordered={false}
                                    mode="multiple"
                                    showArrow
                                    tagRender={tagRender}
                                    style={{ width: '100%', borderBottom: '1px solid var(--primary)' }}
                                    options={options}
                                />
                                </Form.Item>
                            </div>
                        </Col>
                    </Row>
                </Col>


                <Col xs={24} md={8}>
                    <Row gutter={[0, 24]}>
                        <Col span={24}>
                            <div style={{ display: 'flex', justifyContent: 'center' }}>
                                <input id='img_input' onChange={handleEditarPortada} type="file" style={{ display: 'none' }} />
                                <Image preview={false} width={'80%'} height={'190px'} src={curso.img === null ? 'error' : `${curso.img}`} fallback={nuevoCurso} />
                            </div>
                        </Col>
                        {
                            (curso.status !== 2)
                            &&
                            <Col span={24}>
                                <div style={{ display: 'flex', justifyContent: 'center' }}>
                                    <Button onClick={handleCargarImagen} shape='round' className='btn-primary'>
                                        + Subir una Imagen
                                    </Button>
                                </div>
                            </Col>
                        }
                        <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ width: '80%', borderLeft: '1px solid #f0f0f0', paddingLeft: '2%' }}>
                                <h4 style={{ fontWeight: 'bold', color: '#707070' }}>
                                    Estado del Curso
                                </h4>
                                <div>
                                    <Form.Item name='estado'>
                                        <Radio.Group onChange={handleChangeStatus} size='small'>
                                            <Space direction='vertical'>
                                                <Radio style={{ fontSize: '13px' }} value={0}>Desarrollo</Radio>
                                                <p style={{ fontSize: '12px', lineHeight: '110%', paddingLeft: '22px' }}>En el estado de desarrollo puedes hacer cambios más significativos al curso y solo es visible para ti</p>
                                                <Radio style={{ fontSize: '13px' }} value={2}>Productivo</Radio>
                                                <p style={{ fontSize: '12px', lineHeight: '110%', paddingLeft: '22px' }}>El estado productivo indica que el curso ya está disponible para los usuarios</p>
                                            </Space>
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col md={24} style={{ display: 'flex', justifyContent: 'center' }}>
                    <Button htmlType='submit' shape='round'  className='btn-gradiente'> 
                        Guardar
                    </Button>
                </Col>
            </Row>
        </Form>
    </div>
  )
}
