import { types } from "../types/types";


const initialState={
    activeButton:null,
    maestro:{},
    actividades: [],
    actividad: {data: {}},
    posteos:{posts:[]},


};

export const maestrosReducer=(state=initialState,action)=>{
    switch (action.type){
        case types.eventSetActive:
            return{
                ...state,
                activeButton:true
            }
        case types.eventClearActiveEvent:
            return{
                ...state,
                activeButton:null
            }
        case types.maestrosLoaded:
            return{
                ...state,
                maestro:{...action.payload}
            }

        case types.actividadesLoaded:
            return{
                ...state,
                actividades:[...action.payload]
            }
    
        case types.actividadLoaded:
            return{
                ...state,
                actividad:{...action.payload, 
                            curso: {
                                ...action.curso
                            },
                            data: {
                                ...action.data
                            }
                }
            }
        
            case types.actividadClear:
                return{
                    ...state,
                    actividad:{}
                }
            case types.postsLoaded:
                return{
                    ...state,
                    posteos:{...action.payload}
                }
            case types.postsClear:
                return{
                    ...state,
                    posteos:{}
                }
        
        default:
            return state;
    }
}