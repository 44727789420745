import React, { useState } from 'react';
import { UserOutlined,UserAddOutlined, PictureOutlined} from '@ant-design/icons';
import {  Button, Form, Image, Input } from 'antd';
import { defaultValidateMessages } from '../../helpers/validateMessages';
import { editarMaestro } from '../../actions/maestros';
import { useDispatch } from 'react-redux';
import { message } from "antd";

export const FormMaestro = ({_id, nombre, apellidos, imagen}) => {
    console.log(imagen);
    const dispatch = useDispatch();
    
    const initValues = {
        nombre: nombre,
        apellidos: apellidos,
    }
    const [img, setImg] = useState(null);
    const [file, setFile] = useState(imagen);

    const imageHandler=(e)=>{
        if (e.target.files && e.target.files[0]) {
            const nuevaImagen = e.target.files[0];
            
            //Validar extensión de imagen
            const allowedExtensions = /(.jpg|.jpeg|.png)$/i;
            if(!allowedExtensions.exec(nuevaImagen.name)){
                message.error('Por favor seleccione un archivo tipo .jpeg .jpg o .png');
            }else{
                setFile(URL.createObjectURL(nuevaImagen));
                setImg(nuevaImagen);
            }
          }
    }

    const handleEditar=(datos)=>{
        dispatch(editarMaestro(_id,datos,img)); 
        setImg(null);
    }
       
    return (
        <div>
             <Form
                initialValues={initValues}
                onFinish={handleEditar}
                validateMessages={defaultValidateMessages}
                >

                
                <Form.Item
                    
                   name="nombre"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    
                    <Input
                    value={nombre} prefix={<UserOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />}
                    type="text"
                    placeholder="Nombre"
                    name="nombre"
                    className="inputs"
                    
                    />
                </Form.Item>

                <Form.Item
                    name="apellidos"
                    rules={[
                    {
                        required: true,
                    },
                    ]}
                >
                    <Input
                    prefix={<UserAddOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />}
                    type="text"
                    placeholder="Apellido(s)"
                    className="inputs"
                   
                    />
                </Form.Item>
                {/* */}
               <Form.Item >
               
                    <div className="img-holder">
                        {
                            (file===null) ? <Image src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png" width={200} height={200} alt="hola" preview={false} ></Image>
                            :  <Image src={file} width={200} height={200} alt="hola" preview={false} ></Image>

                        }
                    </div>
                    <input type="file" name="img" id="input" accept="image/*" onChange={imageHandler} style={{visibility:'hidden'}}></input>
                    <div className="label">
                        <label htmlFor="input" className="image-upload">
                        <PictureOutlined />
                        Elige tu foto
                        </label>
                    </div>

               </Form.Item>

                <Form.Item>
                <div className="label">
                        <Button type="primary" htmlType="submit" style={{backgroundColor: 'var(--primary)',border:'1px solid var(--primary)', width:'250px'}}>
                            Actualizar 
                        </Button>
                    </div>

                </Form.Item>
            </Form>

        
        </div>
    )
}
