import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Switch, Route, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { autoLogin, startLogout } from '../../actions/auth';
// import { Header } from '../../components/ui/Header';
import { SemiprivateRouter } from './SemiprivateRouter';
import { PrivateRoute } from './PrivateRoute';
import { LoadingScreen } from '../../components/ui/LoadingScreen';
// import { Footer } from '../../components/ui/Footer';
import { HomeScreen } from '../../components/publico/HomeScreen';
import { ViewCursoUsuario } from '../../components/usuario/ViewCursoUsuario';
import { CursosScreen } from '../../components/publico/CursosScreen';
import { RecPassFormScreen } from '../../components/auth/RecPassFormScreen';
import { RecPassSolScreen } from '../../components/auth/RecPassSolScreen';
import { CategoriasScreen } from '../../components/publico/CategoriasScreen';
import { MaestrosScreen } from '../../components/usuario/MaestrosScreen';
import { MaestroScreen } from '../../components/usuario/MaestroScreen';

import { Layout } from 'antd'
import { CustomHeader } from '../../components/ui/CustomHeader'
import { CustomFooter } from '../../components/ui/CustomFooter'
import { CustomSidebar } from '../../components/ui/CustomSidebar'
const { Header, Footer, Sider, Content } = Layout

export const UsuariosRouter = ({eleccion}) => {

    const {id, checking, primary, secondary, primary_hover, secondary_hover, primary_light, primary_opacity, secondary_opacity, background_color, sidebar_background_color, sidebar_text_color, sidebar_folder, sidebar_btn_user_primary_color, sidebar_btn_user_secondary_color, footer_text_color, navbar_text_color, navbar_border, navbar_primary_color, navbar_secondary_color, navbar_line} = useSelector(state => state.auth);

    const css = (id) ? `
        :root {
            --primary: ${ primary };
            --secondary: ${ secondary };
            --primary_hover: ${ primary_hover };
            --secondary_hover: ${ secondary_hover };
            --primary_light: ${ primary_light };
            --primary_opacity: ${ primary_opacity };
            --secondary_opacity: ${ secondary_opacity };
            --background_color: ${ background_color };
            --sidebar_background_color: ${ sidebar_background_color };
            --sidebar_text_color: ${ sidebar_text_color };
            --sidebar_folder: ${ sidebar_folder };
            --sidebar_btn_user_primary_color: ${ sidebar_btn_user_primary_color };
            --sidebar_btn_user_secondary_color: ${ sidebar_btn_user_secondary_color };
            --footer_text_color: ${ footer_text_color };
            --navbar_text_color: ${ navbar_text_color };
            --navbar_border: ${ navbar_border };
            --navbar_primary_color: ${ navbar_primary_color };
            --navbar_secondary_color: ${ navbar_secondary_color };
            --navbar_line: ${ navbar_line };
        }
    ` : '';

    const history = useHistory();
  
    const dispatch = useDispatch();

    const handleLogout = () => {
        history.push(`/home`);
        dispatch(startLogout());
    }

    //Recuperar token para autologin de usuarios redireccionados
    const location = useLocation();
    const { token } = queryString.parse(location.search);
    //eliminar token de url
    if(token){
        history.replace({
            search: '',
        });
    }

    useEffect(() => {
        dispatch( autoLogin(token) );
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])

    if(checking){
        return(
            <LoadingScreen />
        )
    }
      
    return (

        <>
            <style>{css}</style>
            <Layout>
                <div className='header-container'>
                    <Header className='header'>
                        <CustomHeader handleLogout={handleLogout} />
                    </Header>
                </div>
                <Layout className='content-container'>
                    {(id) &&
                    <Sider className='content-sidebar' width="80px">
                        <CustomSidebar handleLogout={handleLogout} />
                    </Sider>
                    }
                    <div className='map-container'></div>
                    <Content className='content-main-container'>
                        <div className='index-cursos-usuario'>
                            <Switch>
                                <PrivateRoute path="/user" component={SemiprivateRouter} isAuthenticated={!!id} />
                                {/* <Route exact path="/sol-password" component={RecPassSolScreen} /> */}
                                {/* <Route exact path="/rec-password/(token=:token)?" component={RecPassFormScreen} /> */}
                                <Route exact path="/home" component={HomeScreen} />
                                <Route exact path="/courses/:_id" component={ViewCursoUsuario} />
                                <Route exact path="/categories" component={CategoriasScreen} />
                                <Route path="/courses/categories/:parametro" component={CursosScreen} />
                                <Route path="/courses/search/:parametro" component={CursosScreen} />
                                <Route path="/courses" component={CursosScreen} />
                                <Route exact path="/maestros" component={MaestrosScreen}/>
                                <Route path="/maestro/:_id" component={MaestroScreen}/>
                                <Redirect to="/home" />
                            </Switch>
                        </div>
                    </Content>
                </Layout>
                <Footer className='footer-container'>
                    <CustomFooter />
                </Footer>
            </Layout>
        </>
    )
}