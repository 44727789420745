import React from 'react';
import { Redirect, Switch, useLocation, useHistory } from 'react-router-dom';
import {DocenteAuth} from '../../components/auth/DocenteAuth';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import queryString from 'query-string';
import { autoLoginMaestro } from '../../actions/auth';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { DocentePrivateRouter } from './DocentePrivateRouter';
import { LoadingScreen } from '../../components/ui/LoadingScreen';
import Layout, { Content } from 'antd/lib/layout/layout';

export const DocenteRouter = () => {

    const {id, checking} = useSelector(state => state.auth)
    const dispatch = useDispatch();

    //Recuperar token para autologin de usuarios redireccionados
    const location = useLocation();    
    const history = useHistory();
    const { token } = queryString.parse(location.search);
    //eliminar token de url
    if(token){
        history.replace({
            search: '',
        });
    }

    useEffect(() => {
        dispatch(autoLoginMaestro(token));
    },[dispatch, token])

    if(checking){
        return(
            <LoadingScreen />
        )
    }

    return (
        <>
            <Layout>
                <Content>
                    <Switch>

                        <PublicRoute
                            path="/docente/auth"
                            component={DocenteAuth}
                            isAuthenticated={!!id}
                        />

                        <PrivateRoute 
                            path="/docente/"
                            component={DocentePrivateRouter}
                            isAuthenticated={!!id}
                        />

                        <Redirect to="/docente/auth" />

                    </Switch>
                </Content>
            </Layout>
        </>
    )
}
