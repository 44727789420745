import React, { useEffect} from 'react'

import { Layout, Space } from 'antd';
import { UserOutlined, SmallDashOutlined, LockOutlined } from '@ant-design/icons';
import {  Divider } from 'antd'
import { FormMaestro } from './FormMaestro';
import { useDispatch, useSelector } from 'react-redux';
import { PasswdMaestro } from './PasswdMaestro';
import { Tabs } from 'antd';
import { ProfMaestro } from './ProfMaestro';
import { maestrosStartLoading } from '../../actions/maestros';

const { TabPane } = Tabs;

const { Content } = Layout;
export const DocenteProfileScreen = () => {

  const dispatch = useDispatch();
  const {id:_id} = useSelector(state => state.auth);
  const {maestro} = useSelector(state => state.maestros);

  useEffect(() => {
    window.scrollTo(0,0);
    dispatch(maestrosStartLoading(_id));
  }, [dispatch,_id]);

    return (
        <div>
            <Layout style={{backgroundColor: 'white'}} className="layout">
              <Content style={{ margin: '24px 16px 0 ' }}>
                <div className="site-layout-background" style={{ padding: 24, minHeight: 400 }}> 
                        <div>
                            <p style={{fontSize:'18px'}}><span className="info">Correo:</span> {maestro.email}</p>
                            <p style={{fontSize:'18px'}}><span className="info">Profesion: </span> {maestro.profesion}</p>
                            <p style={{fontSize:'18px'}}><span className="info">Resumen:</span> {maestro.resumen}</p>
                        </div>
      
                    <Divider orientation="left" style={{marginBottom:'1px'}}></Divider>
                    <div>
                    <Space align="left">
                    
                    <Tabs defaultActiveKey="1"  >
                        <TabPane
                          tab={ 
                            <span className="tabs">
                              <UserOutlined />
                               Datos generales del docente
                            </span>
                          }
                          key="1"
                        >
                          {
                            (maestro._id) && <FormMaestro _id={maestro._id} nombre={maestro.nombre} apellidos={maestro.apellidos} imagen={maestro.img}/>
                          }
                        </TabPane>
                        <TabPane
                          tab={
                            <span className="tabs">
                              <SmallDashOutlined />
                              Datos profesionales del docente
                            </span>
                          }
                          key="2"
                        >
                          {
                            (maestro._id) &&  <ProfMaestro _id={maestro._id} profesion={maestro.profesion} resumen={maestro.resumen} />
                          }
                         
                        </TabPane>
                        <TabPane
                          tab={
                            <span className="tabs">
                              <LockOutlined/>
                              Cambio de Contraseña
                            </span>
                          }
                          key="3"
                        >
                          <PasswdMaestro />
                        </TabPane>
                      </Tabs>
                     
                    </Space>
                   
                    <br />
                    
                    </div>
                </div>
              </Content>
          </Layout>
        </div>
    )
}