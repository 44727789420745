import React from 'react';
import { useDispatch } from 'react-redux';
import { Button, Col, Progress, Row } from 'antd';
import { TrophyFilled, DownloadOutlined } from '@ant-design/icons';
import { currentActivity, currentActividadFinal } from '../../actions/usuarios';
import { useEffect } from 'react';

export const ViewMakeCourseEnd = ({current, handleCertificado, setStatusCargaVista}) => {

    const dispatch = useDispatch();

    useEffect(() => {
      
        dispatch(currentActivity(current.curso._id, setStatusCargaVista, undefined, true))
    
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    

    const handleCourse=()=>{
        dispatch(currentActividadFinal({ endView: false }));
    }

    return (
        <Col md={18}>
            <Row>
                <div className='viewMakeCourse-actividad-titulo w-100 text-center mt-20 mb-20'>¡Felicidades has completado el curso {current.curso.nombre}!</div>
            </Row>
            <Row>
                <div className='viewMakeCourse-actividad-end-text'>
                    <p>Queremos expresar nuestro más sincero agradecimiento por tu dedicación y compromiso durante este viaje de aprendizaje. El tiempo y esfuerzo que has invertido no pasan desapercibidos, y estamos realmente impresionados por tu determinación.</p>
                    <p>Esperamos que hayas encontrado este curso tan enriquecedor como nosotros. Tu finalización es un testimonio de tu perseverancia y deseo de crecer y aprender.</p>
                    <p>¡Felicitaciones nuevamente por este logro! Estamos emocionados por verte aplicar lo que has aprendido y esperamos que continúes avanzando en tu camino hacia el éxito</p>
                    <p>No olvides evaluar el curso, ahora que lo has finalizado ya tienes una opinión completa, con tu evaluación podemos mejorar el desarrollo y contenido de los cursos ofrecidos.</p>
                    <p>¡Gracias por ser parte de nuestra comunidad de aprendizaje y esperamos verte alcanzar grandes logros en el futuro!</p>
                </div>
            </Row>
            <Row>
                <div className='w-100 centrar'>
                    <div className='viewMakeCourse-progress-container'>
                        <Progress
                        type="circle"
                        strokeColor='var(--primary)'
                        trailColor='#1b2a49'
                        percent={parseInt(current.progress_percentaje)}
                        width={100}
                        format={()=>(current.progress_percentaje==="100") ? <TrophyFilled onClick={()=>handleCertificado(current.curso._id)} style={{color: 'var(--primary)'}}/> : <TrophyFilled style={{color: '#1b2a49'}}/>}
                        />
                    </div>
                </div>
                <div className='w-100 centrar mt-10 mb-10'>
                    <Button
                        className='viewMakeCourse-progress-reset-btn'
                        icon={<DownloadOutlined />}
                        onClick={()=>handleCertificado(current.curso._id)}
                        type="primary"
                    >
                        Descargar certificado
                    </Button>
                </div>
                <div className='w-100 viewMakeCourse-actividad-descarga-text'>Recuerda que puedes descargar tu certificado en cualquier momento dando click en el icono de trofe de la barra de progreso del curso.</div>
                <div className='viewMakeCourse-actividad-back-text w-100' onClick={handleCourse}>
                    Regresar al curso
                    {/* <Button type="primary" onClick={handleCourse} style={{marginLeft:'auto',marginRight:'auto',marginBottom:'5px',display:'block',backgroundColor: 'var(--primary)', color: 'white', fontWeight: 'bold',width:'400px',border:'none',fontSize:'17px'}} >Regresar al curso</Button> */}
                </div>
            </Row>
        </Col>
    )
}
