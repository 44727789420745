import { Form, Input, Button } from 'antd';
import React from 'react';
import { useDispatch } from 'react-redux';
import { defaultValidateMessages } from '../../helpers/validateMessages';
import { MailOutlined ,LockOutlined} from '@ant-design/icons';
import { startLoginAdmin } from '../../actions/auth';
import { CustomFooter } from '../ui/CustomFooter';
import { Header } from 'antd/lib/layout/layout';

export const AdminAuth = () => {

    const dispatch = useDispatch();

    const handleLogin = (datos) => {
        dispatch(startLoginAdmin(datos));
    }

    return (
        <>
        <Header
            style={{backgroundColor: '#fff'}}
            className="navbar-admin"
        />
        <div className="auth-center-admin login-admin">
                <div className="docente-auth-card">
                    
                    <h2>Iniciar sesión</h2>
                    
                    <Form 
                        className="login-form"
                        style={{paddingTop: '20px'}}
                        validateMessages={defaultValidateMessages}
                        onFinish={handleLogin}
                    >
                        <Form.Item
                            name="email"
                            rules={[
                                {
                                required: true,
                                type: 'email'
                                },
                            ]}
                        >
                            <Input 
                                prefix={<MailOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />} 
                                placeholder="Correo" 
                                autoComplete="off"
                            />
                        </Form.Item>
                            
                        <Form.Item
                            name="password"
                            rules={[
                                {
                                required: true,
                                },
                            ]}
                        >
                            <Input
                                prefix={<LockOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />}
                                type="password"
                                placeholder="Password"
                                autoComplete="off"
                            />
                        </Form.Item>

                        <Form.Item>
                            <Button block type="primary" htmlType="submit" className="login-form-button btn-login">
                                Iniciar sesión
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
        </div>
        <CustomFooter />
        </>
    )
}
