import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table, Button, Modal, Menu, Dropdown, Pagination, Input } from 'antd';
import { ExclamationCircleOutlined, MenuOutlined, SearchOutlined, LeftOutlined } from '@ant-design/icons';

import { getUsuarios, startDeleteUser, getUser } from '../../actions/admin';
import { AdminModalUsuario } from './UI/AdminModalUsuario';
import { AdminFichaUsuario } from './UI/AdminFichaUsuario';
import { CRUDTableSkeleton } from './UI/CRUDTableSkeleton';
import { Link } from 'react-router-dom';


export const AdminUsuariosScreen = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    const initData = {
        _id: '',
        nombre: '',
        apellidos: '',
        email: '',
        password: ''
    }

    const [usuarios, setUsuarios] = useState({
        items: [],
        total: 5
    });
    
    const [params, setParams] = useState({
        pagina: 1,
        limit: 8
    });
    const [data, setData] = useState(initData);
    const [dataFicha, setDataFicha] = useState({
        nombre: '',
        apellidos: '',
        email: ''
    });
    const [visible, setVisible] = useState(false);
    const [visibleFicha, setVisibleFicha] = useState(false);
    const { confirm } = Modal;

    useEffect(() => {
        dispatch(getUsuarios(setUsuarios, params, setLoading));
    }, [dispatch, params]);

    const cambioPagina = (pagina) => {
        setParams({
            ...params,
            pagina: pagina
        });
    }

    const handleNuevo = () => {
        setVisible(true);
    }

    const handleEdit = () => {
        dispatch(getUser(data._id, setVisible, setData));
    }

    const handleDeleteUser = (id) => {
        dispatch(startDeleteUser(id, setUsuarios, params));
        setData(initData);
    }

    const showDeleteConfirm = (id, nombre) =>  {
        confirm({
          title: '¿Seguro que desea eliminar al administrador?',
          icon: <ExclamationCircleOutlined />,
          content: `Se eliminará  "${nombre}"`,
          okText: 'Aceptar',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk() {
            // console.log('OK');
            handleDeleteUser(id);
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
    }

    const HandleShowFicha = (id) => {
        dispatch(getUser(id, setVisibleFicha, setDataFicha));
    }

    const columns = [
        {
            title:'Nombre', 
            dataIndex:'nombre', 
            key:'nombre',
            render: (text, record) => 
                <Button
                    type="link"
                    onClick={() => HandleShowFicha(record.key)}>
                        {text}
                </Button>
        },
        {
            title:'Apellidos',
            dataIndex:'apellidos',
            key:'apellidos',
        },
        {
            title:'Email',
            dataIndex:'email',
            key:'email',
            responsive: ['md']
        },
        {
            width: '10%',
            title: 'Opciones',
            dataIndex: 'opciones',
            key: 'opciones' ,
            render: (text, record) => {
                    return (
                        <Dropdown trigger={['click']}  overlay={menu}>
                            <Button 
                                shape='round' 
                                size='middle' 
                                icon={<MenuOutlined />} 
                                className="btn-crud-opciones"
                                onClick={() => {
                                    setData({
                                        ...data, 
                                        _id: record._id,
                                        nombre: record.nombre
                                    })
                                }} 
                            />
                        </Dropdown>
                    )
            }
        }
    ]

    const menu = (
        <Menu>
            <Menu.Item key="editar" onClick={() =>{handleEdit()}}>
                Editar
            </Menu.Item>
            <Menu.Item key="eliminar" onClick={() =>{showDeleteConfirm(data._id, data.nombre)}}>
                Eliminar
            </Menu.Item>
        </Menu>
    )

    return (
        <>
            <div>
                <h1 className="admin-crud-titulo">Administradores</h1>
            </div>
            <Link to="/admin" className="admin-btn-atras">
                <div className="admin-btn-atras"><LeftOutlined className="icon-atras" />  atrás</div>
            </Link>

            <div style={{display: 'flex', marginBottom: '20px'}}>
                <Button className="nuevo-boton" onClick={handleNuevo}>
                    Nuevo Usuario
                </Button>
                <Input className="admin-input-buscador" placeholder="Buscar" prefix={<SearchOutlined className="search-icon" />} />
            </div>
            
            {
                (loading) ? 
                (
                    <CRUDTableSkeleton columns={columns} loading={loading} />
                    
                ) : 
                (
                    <Table dataSource={usuarios.items}
                    pagination={false} columns={columns} />
                )
            }
            
            <div style={{width: '100%', textAlign: 'center', marginTop: '15px'}}>
                <Pagination onChange={cambioPagina} pageSize={5} total={usuarios.total} responsive={true} />
            </div>

            <AdminModalUsuario 
                params={params}
                visible={visible}
                setVisible={setVisible}
                data={data}
                setData={setData}
                initData={initData}
                setUsuarios={setUsuarios}
            />

            <AdminFichaUsuario
                visible={visibleFicha}
                setVisible={setVisibleFicha}
                data={dataFicha}
            />
        </>
    )
}
