import React from 'react'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Row, Col } from 'antd'
import { PhoneOutlined, MailOutlined, FacebookFilled, InstagramFilled, YoutubeFilled, WhatsAppOutlined, LinkedinFilled } from '@ant-design/icons'

import logo from '../../assets/logoEdeX.jpg'

export const CustomFooter = () => {

    // eslint-disable-next-line no-unused-vars
    const {id} = useSelector(state => state.auth);

    return (
        <div className="footer">
            {(id)
            ? <div className='footer-login'>
                <div className='centrar footer-icon-container'>
                    <a style={{color: 'white'}} href="https://www.facebook.com/iivvo.oficial"><FacebookFilled className="footer-icon"/></a>
                    <a style={{color: 'white'}} href="https://www.instagram.com/iivvo.oficial/"><InstagramFilled className="footer-icon"/></a>
                    <a style={{color: 'white'}} href="https://www.youtube.com/channel/UC1r0DPbF1X5SyyJdTpSAIvw"><YoutubeFilled className="footer-icon"/></a>
                    <a style={{color: 'white'}} href="https://www.linkedin.com/company/iivvo---conectamos-a-j%C3%B3venes-con-universidades"><LinkedinFilled className="footer-icon"/></a>
                    <a style={{color: 'white'}} href="https://api.whatsapp.com/send?phone=8112125982"><WhatsAppOutlined className="footer-icon"/></a>
                </div>
                <div className='footer-text'>Educación exponencial ® 2016 - {new Date().getFullYear()}</div>
            </div>
            : <div className='footer-logout'>
                <Row justify="space-between" style={{padding: '20px', overflowX: 'hidden'}}>
                    <Col xs={24} sm={24} lg={5} className="footer-card" style={{alignItems: 'center'}}>
                        <img className="footer-logo"  alt="logo EdeX" src={logo}></img>
                        <div>
                            <a style={{color: 'white'}} href="https://www.facebook.com/iivvo.oficial"><FacebookFilled className="footer-icon"/></a>
                            <a style={{color: 'white'}} href="https://www.instagram.com/iivvo.oficial/"><InstagramFilled className="footer-icon"/></a>
                            <a style={{color: 'white'}} href="https://www.youtube.com/channel/UC1r0DPbF1X5SyyJdTpSAIvw"><YoutubeFilled className="footer-icon"/></a>
                            <a style={{color: 'white'}} href="https://www.linkedin.com/company/iivvo---conectamos-a-j%C3%B3venes-con-universidades"><LinkedinFilled className="footer-icon"/></a>
                            <a style={{color: 'white'}} href="https://api.whatsapp.com/send?phone=8112125982"><WhatsAppOutlined className="footer-icon"/></a>
                        </div>
                    </Col>
                    <Col xs={24} sm={11} lg={5} className="footer-card">
                        <div className="footer-titulo bold">Misión</div>
                        <div>Ayudar a los jóvenes a decidir mejor su profesión
                        masivamente a través de inteligencia artificial y
                        contribuir a reducir la deserción escolar en
                        América Latina.</div>

                        <div className="footer-titulo bold">Visión</div>
                        <div>Ser el test vocacional simple y confiable elegido
                        por los jóvenes para descubrir su profesión.
                        </div>
                    </Col> 
                    <Col xs={24} sm={11} lg={5} className="footer-card footer-links" >
                        <Link to="/politicas?q=2" style={{color: '#fff'}}>
                            Términos y condiciones  
                        </Link>
                        <Link to="/politicas?q=1" style={{color: '#fff'}}>
                            Políticas de privacidad 
                        </Link>
                    </Col> 
                    <Col xs={24} sm={11} lg={5} className="footer-card">
                        <div><MailOutlined /> hola@iivvo.com</div>
                        <div><PhoneOutlined /> +52 (81) 84210022 +52 (1) 8112125982</div>
                        <div>Torre Cibeles. Calle arquitecto Lisandro</div>
                        <div>Peña, C.P. 64909 Monterrey, México</div>
                    </Col> 
                </Row>
            </div>}
        </div>
    )
}
