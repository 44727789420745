import { ArrowDownOutlined, ArrowUpOutlined, DeleteFilled, FileTextFilled, QuestionCircleFilled, PlayCircleFilled } from '@ant-design/icons';
import { Button, Card, Col, Input, Row,Form, Skeleton, Popconfirm, Radio  } from 'antd'
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { actividadesStartLoading,  bajarNivelActividad,  crearActividad, eliminarActividad, subirNivelActividad } from '../../actions/maestros';
import { defaultValidateMessages } from '../../helpers/validateMessages';

export const ActividadesDocente = ({temaId, cursoStatus, handleMostrarActividad, actividad}) => {

    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const _id=temaId;
    const [actividades, setActividades] = useState([]);
    const [loading, setLoading] = useState(true);
    const [actualizar, setActualizar] = useState(false);
    const [showFormActividad, setShowFormActividad] = useState({
        button: false,
        form: true
    });

    useEffect(() => {
       dispatch(actividadesStartLoading(_id, setLoading, setActividades));
    }, [dispatch, _id, setLoading, actualizar])

    const handleShowFormActividad = () => {
        setShowFormActividad({
            button: true,
            form: false
        });
    };

    const handleCancelar = () => {
        setShowFormActividad({
            button: false,
            form: true
        });

        form.resetFields();
    };

    const handleSelectActividad=(_id)=>{
        handleMostrarActividad(_id);
    }

    const handleCrear=(datos)=>{
        let data = {};
        switch (datos.tipo) {
            case 'texto':
                data = {
                    nombre: datos.nombre,
                    descripcion: 'Descripción de ' + datos.nombre,
                    tipo: 0
                }
                dispatch(crearActividad(_id, data, setActualizar, actualizar, handleSelectActividad));
                break;
            case 'quiz':
                data = {
                    nombre: datos.nombre,
                    descripcion: 'Descripción de ' + datos.nombre,
                    tipo: 1
                }
                dispatch(crearActividad(_id, data, setActualizar, actualizar, handleSelectActividad));
                break;
            case 'video':
                data = {
                    nombre: datos.nombre,
                    descripcion: 'Descripción de ' + datos.nombre,
                    tipo: 2
                }
                dispatch(crearActividad(_id, data, setActualizar, actualizar, handleSelectActividad));
                break;
                    
            default:
                break;
        }
        handleCancelar();
    }

    const levelUp=(valor)=>{
        dispatch(subirNivelActividad(_id, valor, setActualizar, actualizar))
    }
    const levelDown=(valor)=>{
        dispatch(bajarNivelActividad(_id,valor, setActualizar, actualizar))
    }
    const handleDelete=(val)=>{
        dispatch(eliminarActividad(_id,val, setActualizar, actualizar))
    }

    return (
        <div>

            {
                (loading === false)
                    ?
                        actividades.map(datos=>{
                            return(
                                <Row  key={datos._id}>
                                    <Col span={24} xs={24} s={16}>
                                                    
                                    <Card 
                                        className={(actividad === datos._id) && 'p-card-selected'} 
                                        bodyStyle={{padding:'5px 15px 24px', height: 'inherit',display: 'flex'}} style={{height: '35px', cursor: 'pointer'}} 
                                    >
                                        {
                                            <>
                                                <div style={{width: '75%'}}>
                                                    <div 
                                                        onClick={()=>{handleSelectActividad(datos._id)}}
                                                        className='nombre-actividad'
                                                    >
                                                        {datos.nombre}
                                                    </div>
                                                </div> 

                                                {
                                                    (cursoStatus !== 2)
                                                        ?
                                                            <div style={{ display:'flex',alignItems:'end',justifyContent: 'end',paddingTop:'2px',margin:'auto'}}>
                                                                {(datos.tipo === 0) && <FileTextFilled style={{ fontSize: '1.1em', color: 'grey' }} />}
                                                                {(datos.tipo === 1) && <QuestionCircleFilled style={{ fontSize: '1em', color: 'grey', marginRight: (datos.end === 1) && '58px' }} />}
                                                                {(datos.tipo === 2) && <PlayCircleFilled style={{ fontSize: '1em', color: 'grey' }} />}

                                                                <Popconfirm
                                                                    placement='topRight'
                                                                    title={'Está seguro de eliminar esta actividad'}
                                                                    okText='Sí, eliminar'
                                                                    cancelText='Cancelar'
                                                                    onConfirm={evento=>{
                                                                        handleDelete(datos._id);
                                                                        evento.stopPropagation();
                                                                    }}
                                                                    onCancel={evento=>{
                                                                        evento.stopPropagation()
                                                                    }}
                                                                >
                                                                    {
                                                                        (datos.end === 0) && (
                                                                            
                                                                            <DeleteFilled 
                                                                                style={{ fontSize: '1.2em', color: 'grey'}}
                                                                                title="Eliminar actividad"
                                                                                onClick={evento => {
                                                                                    evento.stopPropagation();
                                                                                }}
                                                                            />

                                                                        )
                                                                    }
                                                                    
                                                                </Popconfirm>
            
                                                                {
                                                                    (datos.end === 0) && (
                                                                        <>
                                                                        <ArrowUpOutlined onClick={()=>{levelUp(datos._id)}} style={{ fontSize: '1.2em', color: 'grey',marginLeft:'8px'}} title="Subir nivel de actividad"/>
                                                                        <ArrowDownOutlined onClick={()=>{levelDown(datos._id)}} style={{ fontSize: '1.2em', color: 'grey' }} title="Bajar nivel de actividad"/>
                                                                        </> 
                                                                    )
                                                                }

                                                           </div>
                                                        :
                                                        <div style={{ display:'flex',alignItems:'end',justifyContent: 'end',paddingTop:'2px',margin:'auto'}}>
                                                            {(datos.tipo === 0) && <FileTextFilled style={{ fontSize: '1.2em', color: 'grey' }} />}
                                                            {(datos.tipo === 1) && <QuestionCircleFilled style={{ fontSize: '1em', color: 'grey' }} />}
                                                            {(datos.tipo === 2) && <PlayCircleFilled style={{ fontSize: '1em', color: 'grey' }} />}
                                                        </div>
                                                }   
                                                
                                            </>
                                        }
                                            
                                    </Card>
                                        
                                    </Col>  
                                </Row>
                                    
                            )
                        }) 

                    :
                        <div>

                            <Row>
                                <Col span={24} xs={24} s={16}>
                                    <Card className='p-card' bodyStyle={{paddingTop: '5px', paddingLeft: '15px'}} style={{height: '35px'}}>
                                    {
                                        (cursoStatus !== 2)
                                            ?                            
                                                <Row>
                                                    <Col span={19}>
                                                        <Skeleton active title={false} paragraph={{rows: 1}} />
                                                    </Col>
                                                    <Col span={2}><Skeleton.Avatar  size={'small'}/></Col>
                                                    <Col span={1}><Skeleton.Avatar  size={'small'}/></Col>
                                                    <Col span={2}><Skeleton.Avatar  size={'small'}/></Col>
                                                </Row>
                                            :
                                                <Row>
                                                    <Col span={19}>
                                                        <Skeleton active title={false} paragraph={{rows: 1}} />
                                                    </Col>
                                                </Row>
                                    }
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} xs={24} s={16}>
                                    <Card className='p-card' bodyStyle={{paddingTop: '5px', paddingLeft: '15px'}} style={{height: '35px'}}>
                                    {
                                        (cursoStatus !== 2)
                                            ?                            
                                                <Row>
                                                    <Col span={19}>
                                                        <Skeleton active title={false} paragraph={{rows: 1}} />
                                                    </Col>
                                                    <Col span={2}><Skeleton.Avatar  size={'small'}/></Col>
                                                    <Col span={1}><Skeleton.Avatar  size={'small'}/></Col>
                                                    <Col span={2}><Skeleton.Avatar  size={'small'}/></Col>
                                                </Row>
                                            :
                                                <Row>
                                                    <Col span={19}>
                                                        <Skeleton active title={false} paragraph={{rows: 1}} />
                                                    </Col>
                                                </Row>
                                    }
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24} xs={24} s={16}>
                                    <Card className='p-card' bodyStyle={{paddingTop: '5px', paddingLeft: '15px'}} style={{height: '35px'}}>
                                    {
                                        (cursoStatus !== 2)
                                            ?                            
                                                <Row>
                                                    <Col span={19}>
                                                        <Skeleton active title={false} paragraph={{rows: 1}} />
                                                    </Col>
                                                    <Col span={2}><Skeleton.Avatar  size={'small'}/></Col>
                                                    <Col span={1}><Skeleton.Avatar  size={'small'}/></Col>
                                                    <Col span={2}><Skeleton.Avatar  size={'small'}/></Col>
                                                </Row>
                                            :
                                                <Row>
                                                    <Col span={19}>
                                                        <Skeleton active title={false} paragraph={{rows: 1}} />
                                                    </Col>
                                                </Row>
                                    }
                                    </Card>
                                </Col>
                            </Row>
                            
                        </div>
            }
            
            
            {
                (cursoStatus !== 2)
                    &&
                        <Row>
                            <Col span={24} style={{padding: '15px'}} hidden={showFormActividad.form}>
                                <Form
                                    form={form}
                                    onFinish={handleCrear}
                                    validateMessages={defaultValidateMessages}
                                    initialValues={{'tipo': 'texto'}}
                                >

                                    <Form.Item
                                        name="nombre"
                                        rules={[{required: true}]}
                                        style={{marginBottom: '5px'}}
                                    >
                                        <Input
                                    
                                        placeholder="Nombre de la actividad" 
                                        type="text"
                                        id='nombreActividad'
                                        
                                        />
                                    </Form.Item>

                                    <Form.Item name="tipo" label={'Tipo de actividad:'} style={{marginBottom: '5px'}}>
                                        <Radio.Group>
                                            <Radio value={'texto'}>Texto</Radio>
                                            <Radio value={'quiz'}>Quiz</Radio>
                                            <Radio value={'video'}>Video</Radio>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Row gutter={[12,12]}>
                                        <Col span={12}>
                                            <Form.Item style={{marginBottom: '5px', justifyContent: 'end'}}>
                                                <Button type="primary" htmlType="submit" style={{width: '100%', display:'flex',justifyContent:'center',backgroundColor:'var(--secondary)',border:'none'}}>
                                                    Agregar
                                                </Button>
                                            </Form.Item>
                                        </Col>
                                        <Col span={12}>
                                            <Button onClick={handleCancelar} type="primary" style={{width: '100%', display:'flex',justifyContent:'center',backgroundColor:'#fff',border:'1px solid #dddddd', color: '#000'}}>
                                                Cancelar
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>

                            <Col hidden={showFormActividad.button} span={12} xs={24} s={16}>
                                <Card bodyStyle={{padding: '0'}} style={{height: '40px', display: 'flex',alignItems: 'center', justifyContent: 'center'}}>
                                <Button className='btn-link-agregar' onClick={handleShowFormActividad} style={{border:'none',color:'grey'}}>
                                    + Agregar actividad
                                </Button>
                                </Card>
                            </Col>
                        </Row>
            }

            
        </div>
    )
}