import React, { useState } from 'react';
import { Row, Col, Card, Rate, Skeleton, Pagination } from 'antd';
import { Link } from 'react-router-dom';
import { UserOutlined, CheckCircleOutlined, LeftOutlined } from '@ant-design/icons';
import { useEffect } from 'react';
import { getCursos } from '../../actions/admin';
import { useDispatch } from 'react-redux';

export const AdminCursosScreen = () => {
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(true);
    const [cursos, setCursos] = useState({
        items: [],
        total: 8
    });
    const [params, setParams] = useState({
        pagina: 1,
        search: '',
        filter: 0,
        filter_key: '',
        limit: 8
    });
    
    const cambioPagina = (pagina) => {
        setParams({
            ...params,
            pag: pagina
        });
    }

    useEffect(() => {
        dispatch(getCursos(setCursos, params, setLoading));
    }, [dispatch, params]);

    console.log(loading);

    return (
        <>
            <div>
                <h1 className="admin-crud-titulo">Cursos</h1>
            </div>
            <Link to="/admin" className="admin-btn-atras">
                <div className="admin-btn-atras"><LeftOutlined className="icon-atras" />  atrás</div>
            </Link>

            <Row gutter={[16, 16]} className="maestro-cursos">
            { 
                (loading) ?
                ([0,1,2,3,4,5,6,7].map(n => (
                    <Col xs={24} sm={12} md={6} lg={6} xl={6}>
                        <Card>
                            <Skeleton loading={loading}>
                            </Skeleton>
                        </Card>
                    </Col>
                ))
                ):((cursos.items.length > 0) ? 
                    (cursos.items.map ((curso) => {
                        return (
                            <Col xs={24} sm={12} md={6} lg={6} xl={6} key={curso._id}>
                                <Link to={"/admin/curso/"+curso._id} key={curso._id}>
                                    <Card
                                        hoverable
                                        // cover={<img className="admin-cursos-img" alt="" src={(curso.img) ? curso.img : portada} />}
                                    >
                                        <Card.Grid hoverable={false} style={{width: '100%', height:'70px', boxShadow: 'none'}}>
                                            <div className="card-titulo bold">{curso.nombre}</div>
                                        </Card.Grid>

                                        <Card.Grid hoverable={false} style={{width: '100%', boxShadow: 'none', padding: '0px'}}>
                                            <div className="card-titulo">Maestro:  {curso.maestro_nombre}</div>
                                        </Card.Grid>

                                        <Card.Grid hoverable={false} style={{width: '100%', boxShadow: 'none', padding: '0px'}}>
                                            <div className="card-titulo">Fecha de registro:  {curso.fecha_registro}</div>
                                        </Card.Grid>

                                        <Card.Grid hoverable={false} style={{width: '100%', boxShadow: 'none'}}>
                                            <div style={{display: 'flex'}}>
                                                <span><h3>{curso.rate}</h3></span>
                                                <Rate disabled allowHalf defaultValue={curso.rate} style={{fontSize: '14px', color: 'orange', margin: '0px 5px 0px 5px'}} />
                                                <span>({curso.no_evaluaciones})</span>
                                            </div>
                                            <div className="card-maestro-footer">
                                                <div style={{display: 'flex'}}>
                                                    <div><UserOutlined style={{color: 'var(--primary)'}} /> {curso.no_participantes}</div>
                                                    <div style={{color: 'var(--primary)', marginLeft: '15px'}}>{(curso.precio === '0') ? "Gratis" : '$' + curso.precio}</div>
                                                </div> 
                                                <div><CheckCircleOutlined style={{color: (curso.status === 0) ? 'grey' : 'green'}} /></div>
                                            </div>
                                            <div style={{textAlign: 'right'}}>
                                                
                                            </div>
                                        </Card.Grid>
                                    </Card>
                                </Link>
                            </Col>
                        )
                    })
                    ):(
                        <div className="no-disponible">No hay cursos disponibles</div>
                    )
                )
            }
                <div style={{width: '100%', textAlign: 'center', marginTop: '15px'}}>
                    <Pagination onChange={cambioPagina} pageSize={params.limit} total={cursos.total} responsive={true} />
                </div>
            </Row>
        </>
    )
}
