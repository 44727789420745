import {  Col, Row, Radio, Skeleton, Button } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { FontSizeOutlined, QuestionCircleOutlined, EditOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {  useParams } from 'react-router-dom'
import {  editarActividad ,viewActividad } from '../../actions/maestros'
import { DocenteQuizScreen } from './DocenteQuizScreen';
import { DocenteTextScreen } from './DocenteTextScreen';

export const VistaActividadScreen = () => {
    
    const dispatch = useDispatch();
    
    const {actividad} = useSelector(state => state.maestros);
    const [visButtonTitulo, setVisButtonTitulo] = useState(false);

    const {_id}=useParams();
    //const [statusCarga, setStatusCarga] = useState(false);
    
    useEffect(() => {
        window.scrollTo(0,0)
        dispatch(viewActividad(_id))
   }, [dispatch,_id]);
    


    const handleEditar=(datos)=>{
        dispatch(editarActividad(_id,datos)) 
    }
   
    const handleTitulo=()=>{
        setVisButtonTitulo(false);

        const nombre=document.getElementById('titulo').value;
        const datos={
            nombre:nombre,
            descripcion:actividad.descripcion,
            tipo:actividad.tipo
        }
        handleEditar(datos);

    }
    const handleDesc=()=>{
        const descripcion=document.getElementById('descripcion').value;
        const datos={
            nombre:actividad.nombre,
            descripcion:descripcion,
            tipo:actividad.tipo
        }
        handleEditar(datos);

    }
   
    

    return (
        <>
        {
           
                <div style={{padding:'40px 8% 40px 8%', backgroundColor: 'white'}}>
                    <Row gutter={24}>
                        <Col className='gutter-row' style={{border:'none', marginBottom: '30px'}} xs={24} sm={6} >
                            <h1 style={{textAlign:'center',fontSize:'20px',color:'#80837C'}}>Tipo de actividad</h1>
                            {
                                (actividad.nombre) && (
                                    <Radio.Group disabled={true} defaultValue={actividad.tipo} size="large" style={{textAlign:'center',display:'flex', alignItems:'center',justifyContent:'center'}}  >
                                
                                        <Radio.Button value={0} style={{width:'90px',height:'80px'}}> <FontSizeOutlined style={{color:' var(--primary)',fontSize:'38px'}} /> <span style={{color:' var(--primary)'}}>Texto</span></Radio.Button>
                                        
                                        <Radio.Button value={1} style={{width:'90px',height:'80px'}}><QuestionCircleOutlined style={{color:' var(--primary)',fontSize:'38px'}} /><span style={{color:' var(--primary)'}}>Quiz</span></Radio.Button>
                                    </Radio.Group>

                                )
                            }
                            
                            
                        </Col>
                        <Col className='gutter-row' xs={24} sm={18} >
                            <Row>
                                <Col span={24}>
                                    {
                                        (actividad.nombre) 
                                            && 
                                                <div>
                                                    {
                                                        (actividad.end === 1 || actividad.curso.status === 2)
                                                            ?
                                                               <div style={{width: '100%', textAlign: 'center'}}>
                                                                   <p style={{fontSize: '30px', color: '#80837C'}}>{actividad.nombre}</p>
                                                               </div> 
                                                            :
                                                               <div>
                                                                    <TextArea onFocus={() => {setVisButtonTitulo(true)}} id="titulo" autoSize={true} className="input-titulo" style={{display: 'block', textAlign:'center', color: '#80837C'}} defaultValue={actividad.nombre} />
                                                                    {
                                                                        (visButtonTitulo) 
                                                                            ? 
                                                                            <Button onClick={handleTitulo} size='small' style={{backgroundColor: 'var(--primary)', color: 'white', position: 'absolute', bottom: '10px', right: '10px'}}>Guardar</Button>
                                                                            :
                                                                            <EditOutlined style={{fontSize: '25px', position: 'absolute', bottom: '10px', right: '10px', color: 'grey', opacity: '0.6'}} />
                                                                    }
                                                               </div>
                                                    }
                                                </div>
                                    }
                                    
                                </Col>
                                <Col span={24} style={{marginTop: '20px'}}>
                                    <h2 style={{color:'#80837C'}}>Descripción</h2>
                                    {
                                        (actividad.nombre)
                                            &&
                                                <div>
                                                    {
                                                        (actividad.end === 1 || actividad.curso.status === 2)
                                                            ?
                                                                <div style={{width: '100%', height: '200px', color: '#80837C', padding: '0px 15px 0px 15px' }}>
                                                                    {actividad.descripcion}
                                                                </div>
                                                            :
                                                                <div>
                                                                    {
                                                                        (actividad.nombre) 
                                                                        ? <TextArea id='descripcion'  style={{height: '200px', resize: 'none',color:'#80837C'}}  autoSize={false} defaultValue={actividad.descripcion} onBlur={handleDesc}/>
                                                                        : <Skeleton active paragraph={{rows:5}} title={false}/>
                                                                    }
                                                                </div>
                                                    }
                                                </div>
                                    }
                                </Col>
                            </Row>    

                            <Row style={{marginTop: '20px'}}>
                                <h2 style={{color:'#80837C',textAlign:'center'}}>Contenido</h2>
                                <Col span={24}>
                                    {
                                        (actividad.nombre) ? (
                                           (actividad.tipo===1) ? <DocenteQuizScreen _id={_id} statusCurso={actividad.curso.status}/> : <DocenteTextScreen _id={_id} actividad={actividad} statusCurso={actividad.curso.status}/>
                                        )
                                        : <Skeleton title={false} active paragraph={{rows:5}} />
                                    }
                                </Col>
                            </Row>  
                        </Col>
                    </Row>

                        {/* MODAL PARA SUBIR ARCHIVO */}
                    
                </div>
                
            }    
        </>
    )
}