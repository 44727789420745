import React from 'react'
import PropTypes from 'prop-types'

export const VideoTimeIndicator = ({ duration, played }) => {

    let hoursTotal = Math.trunc(duration / 3600);
    let minutesTotal = Math.trunc(duration / 60);
    let secondsTotal = Math.trunc(duration % 60);
    let hoursPlayed = Math.trunc((duration * played) / 3600);
    let minutesPlayed = Math.trunc((duration * played) / 60);
    let secondsPlayed = Math.trunc((duration * played) % 60);

    if(hoursTotal < 1){
        hoursTotal = null;
    }else if(hoursTotal <= 9){
        hoursTotal = `0${ hoursTotal }`;
    }
    if(minutesTotal <= 9){
        minutesTotal = `0${ minutesTotal }`;
    }
    if(secondsTotal <= 9){
        secondsTotal = `0${ secondsTotal }`;
    }
    if(hoursPlayed <= 9){
        hoursPlayed = `0${ hoursPlayed }`;
    }
    if(minutesPlayed <= 9){
        minutesPlayed = `0${ minutesPlayed }`;
    }
    if(secondsPlayed <= 9){
        secondsPlayed = `0${ secondsPlayed }`;
    }

    return (
        <div className='usuarioVideoScreen-controls-time'>
            { `${(hoursTotal != null) ? `${ hoursPlayed }:`  : ''}${ minutesPlayed }:${ secondsPlayed } / ${(hoursTotal != null) ? `${ hoursTotal }:`  : ''}${ minutesTotal }:${ secondsTotal }` }
        </div>
    )
}

VideoTimeIndicator.propTypes = {
    duration: PropTypes.number.isRequired,
    played: PropTypes.number.isRequired
}