import React, { useState } from 'react';
import { Alert, Button, Form, Input } from 'antd';
import { defaultValidateMessages } from '../../helpers/validateMessages';
import { MailOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { resetPasswordRequestDocente } from '../../actions/auth';
import { Link } from 'react-router-dom';

export const RecPassSolDocenteScreen = () => {

    const [success, setSuccess] = useState(false);

    const dispatch = useDispatch();

    const handleEnviar = (datos) => {
        dispatch(resetPasswordRequestDocente(datos.email, setSuccess));
    }

    

    return (
        <div  className="auth-center">
            <div className="pass-card">

                {
                    (success === true)
                        ?
                            <div>
                                <Alert
                                    message="Listo"
                                    description="Hemos enviado un correo a tu cuenta para que reestablezcas tu contraseña"
                                    showIcon
                                />

                                <Link to="/docente/auth">
                                    <Button block style={{marginTop: '20%', borderColor: 'vsr(--primary)', color: 'vsr(--primary)'}}>Volver a la página principal</Button>
                                </Link>
                            </div>
                        :
                            <div>
                                <h2>Recupera tu contraseña</h2>

                                <p>Ingresa tu correo y te enviaremos un mensaje para que recuperes tu cuenta</p>

                                <Form
                                    validateMessages={defaultValidateMessages}
                                    onFinish={handleEnviar}
                                >
                                    <Form.Item
                                        name="email"
                                    >
                                        <Input
                                            prefix={<MailOutlined style={{color: 'vsr(--primary)'}} className="site-form-item-icon" />}
                                            placeholder="Correo"
                                            autoComplete="off"
                                        >
                                        </Input>
                                    </Form.Item>

                                    <div style={{float: 'right'}} >
                                        <Form.Item>
                                            <Button type="primary" htmlType="submit" className="login-form-button btn-login">Enviar</Button>
                                        </Form.Item>
                                    </div>
                                </Form>
                            </div>
                }

            </div>
        </div>
    )
}
