import { Button, Col, Empty, Row } from 'antd';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { editarActividad, viewActividad } from '../../actions/maestros';
import { DocenteTemasIndex } from './DocenteTemasIndex';
import { DocenteTextScreen } from './DocenteTextScreen';
import { DocenteQuizScreen } from './DocenteQuizScreen';
import { DocenteVideoScreen } from './DocenteVideoScreen';
import Swal from 'sweetalert2';

export const TabContenidoCurso = ({cursoId, cursoData}) => {
    const dispatch = useDispatch();
    const [actividad, setActividad] = useState({_id: 'null'});
    const [updateActividadData, setUpdateActividadData] = useState({titulo: '', descripcion: '', tipo: 0})
    const [editado, setEditado] = useState(false);    
    const [content, setContent] = useState('');

    const handleMostrarActividad = (_id, setIsSelected) => {
        if(editado === true){
            Swal.fire({
                title: 'Aún no ha guardado cambios',
                text: '¿Seguro que desea continuar?',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: 'Continuar',
                denyButtonText: 'Cancelar',
            }).then((result) => {
                if (result.isConfirmed) {
                    setActividad({_id: ''});
                    setUpdateActividadData({titulo: '', descripcion: '', tipo: 0});
                    dispatch(viewActividad(_id, setActividad, setUpdateActividadData));
                    //Actualizar estado de actividad a no editado
                    setEditado(false);
                }
            })
        }else{
            setActividad({
                _id: ''
            })
            setUpdateActividadData({titulo: '', descripcion: '', tipo: 0});
            dispatch(viewActividad(_id, setActividad, setUpdateActividadData));
        }
    }
    
    const handleEditar=()=>{
        const datos = {
            nombre: updateActividadData.titulo,
            descripcion: updateActividadData.descripcion,
            tipo: updateActividadData.tipo 
        }

        dispatch(editarActividad(actividad._id, datos, content, setEditado)) 
    }

    //Función para renderizar tipo de actividad
    const renderActividad = (actividad) =>{
        switch(actividad.tipo){
            case 0: 
                return <DocenteTextScreen 
                        _id={actividad._id} 
                        actividad={actividad} 
                        statusCurso={actividad.curso.status} 
                        recursos={actividad.data.recursos}
                        setEditado={setEditado}
                        content={content}
                        setContent={setContent}
                        updateActividadData={updateActividadData}
                        setUpdateActividadData={setUpdateActividadData}
                    />
                
            case 1: 
                return <DocenteQuizScreen 
                        actividad={actividad}
                        statusCurso={actividad.curso.status}
                        setEditado={setEditado}
                        updateActividadData={updateActividadData}
                        setUpdateActividadData={setUpdateActividadData}
                    />
                  
            case 2: 
                return <DocenteVideoScreen 
                        _id={actividad._id} 
                        actividad={actividad} 
                        statusCurso={actividad.curso.status} 
                        setEditado={setEditado}
                        content={content}
                        setContent={setContent}
                        updateActividadData={updateActividadData}
                        setUpdateActividadData={setUpdateActividadData}
                    />
            default:
                return <div style={{width: '100%', textAlign: 'center', marginTop: '100px',  minHeight: '250px'}}>
                    <Empty description='Actividad no seleccionada' />
                </div>
        }
    }


  return (
      <div style={{ padding: '30px', backgroundColor: '#fff' }}>
          <Row gutter={[24,24]} justify='space-around'>
            <Col sm={24} lg={7}>
                <DocenteTemasIndex 
                    _id={cursoId} 
                    cursoStatus={cursoData.status} 
                    handleMostrarActividad={handleMostrarActividad} 
                    editado={editado}
                    actividad={actividad._id}
                />
            </Col>

            <Col xs={24} lg={14}>
                <div style={{backgroundColor: '#f0f2f5', padding: '10px 15px'}}>
                    <div style={{width: '100%', textAlign: 'end', marginBottom: '8px'}}>
                        {
                            (cursoData.status < 2 && actividad._id !== '' && actividad._id !== 'null' && actividad.end !== 1) && <Button onClick={handleEditar} type="primary" htmlType="submit" style={{backgroundColor:'var(--primary)', border:'none'}} size='small'>Guardar</Button>
                        }
                    </div>

                        {renderActividad(actividad)}

                    <div style={{marginTop: '8px', width: '100%', textAlign: 'end'}}>
                        {
                            (cursoData.status < 2 && actividad._id !== '' && actividad._id !== 'null' && actividad.end !== 1) && <Button onClick={handleEditar} type="primary" htmlType="submit" style={{backgroundColor:'var(--primary)', border:'none'}} size='small'>Guardar</Button>
                        }
                    </div>
                    
                </div>
            </Col>
          </Row>
      </div>
  )
};

