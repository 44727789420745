import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getMaestros } from '../../actions/admin';
import { Dropdown, Button, Menu, Table, Pagination, Modal, Form, Input, Row, Select, Col } from 'antd';
import { MenuOutlined, ExclamationCircleOutlined, SearchOutlined, LeftOutlined } from '@ant-design/icons';
import { CRUDTableSkeleton } from './UI/CRUDTableSkeleton';
import { Link } from 'react-router-dom';
import { Option } from 'antd/lib/mentions';

export const AdminMaestrosScreen = () => {
    const dispatch = useDispatch();
    const [params, setParams] = useState({
        pagina: 1,
        search: '',
        filter: 0,
        filter_key: ''
    });
    const [loaded, setLoaded] = useState(true);
    const { confirm } = Modal;
    const initData = {
        nombre: '',
        email: '',
        profesion: ''
    }
    const [data, setData] = useState(initData);

    const [maestros, setMaestros] = useState({
        maestros: [],
        total: 5
    });

    useEffect(() => {
        dispatch(getMaestros(setMaestros, params, setLoaded));
    }, [dispatch, params]);

    const showDeleteConfirm = (id, nombre) =>  {
        confirm({
          title: '¿Seguro que desea eliminar al administrador?',
          icon: <ExclamationCircleOutlined />,
          content: `Se eliminará  "${nombre}"`,
          okText: 'Aceptar',
          okType: 'danger',
          cancelText: 'Cancelar',
          onOk() {
            // console.log('OK');
            handleDeleteMaestro(id);
          },
          onCancel() {
            // console.log('Cancel');
          },
        });
    }

    const cambioPagina = (pag) => {
        setParams({
            ...params,
            pagina: pag
        });
    }

    const handleBuscar = (search) => {
        setParams({
            ...params,
            pagina: 1,
            search: search.nombre
        })
    }

    const handleBuscando = () => {
        setLoaded(true);
    }

    const handleDeleteMaestro = () => {

    }

    const columns = [
        {
            width: '30%',
            title:'Maestro', 
            dataIndex:'nombre', 
            key:'nombre',
            render: (text, record) => {
                return (
                    <div className="admin-maestros-nombre">
                        {
                            (record.img) 
                            ?(<div style={{backgroundImage: `url(${record.img})`}} className="admin-maestros-img" />)
                            : (<div style={{backgroundImage: `url(${record.img})`}} className="admin-maestros-img admin-maestros-inicales">
                                {record.iniciales}
                            </div>)
                        }
                        <div> {record.nombre} </div>
                    </div>
                )
            }
        },
        {
            width: '30%',
            title:'Email',
            dataIndex:'email',
            key:'email',
        },
        {
            width: '30%',
            title:'Profesion',
            dataIndex:'profesion',
            key:'profesion',
        },
        {
            width: '10%',
            title: 'Opciones',
            dataIndex: 'opciones',
            key: 'opciones' ,
            render: (text, record) => {
                    return (
                        <Dropdown trigger={['click']}  overlay={menu}>
                            <Button 
                                shape='round' 
                                size='middle' 
                                icon={<MenuOutlined />} 
                                className="btn-crud-opciones"
                                onClick={() => {
                                    setData({
                                        ...data, 
                                        _id: record._id,
                                        nombre: record.nombre
                                    })
                                }} 
                            />
                        </Dropdown>
                    )
            }
        }
    ]

    const menu = (
        <Menu>
            <Menu.Item key="ficha">
                <Link to={'/admin/maestro/' + data._id}>Ficha</Link>
            </Menu.Item>
            <Menu.Item key="eliminar" onClick={() =>{showDeleteConfirm(data._id, data.nombre)}}>
                Eliminar
            </Menu.Item>
        </Menu>
    )

    /* Filtros */
    const cambioOrigen = (value) => {
        console.log(value);
        if(value === 0){
            setParams({
                ...params,
                pagina: 1,
                filter: value,
                filter_key: ''
            })
        }else{
            setParams({
                ...params,
                pagina: 1,
                filter: 1,
                filter_key: value
            })
        }
    }
    
    return (
        <>
            <div>
                <h1 className="admin-crud-titulo">Maestros</h1>
            </div>
            <Link to="/admin" className="admin-btn-atras">
                <div className="admin-btn-atras"><LeftOutlined className="icon-atras" />  atrás</div>
            </Link>
            <Form onFinish={handleBuscar} onValuesChange={handleBuscando}>
                <Form.Item name="nombre">
                    <Input className="input-buscador" placeholder="Buscar" prefix={<Button type="text" htmlType="submit" style={{padding: '5px !important'}}><SearchOutlined className="search-icon" /></Button>}/>
                </Form.Item>
            </Form>

            <Row gutter={[16, 16]} style={{marginTop:'-20px'}}>
                <Col className="admin-crud-filter" md={4}>
                    <Select defaultValue="-1" onChange={cambioOrigen} style={{display: 'block'}}>
                        <Option value="-1" disabled>Origen</Option>
                        <Option value="0">Todos</Option>
                        <Option value="Local">Local</Option>
                        <Option value="iiVVO">iiVVO</Option>
                        <Option value="Psicometricos">Psicometricos</Option>
                    </Select>
                </Col>
            </Row>

            {
                (!loaded) ? 
                (
                    <Table dataSource={maestros.maestros}
                    pagination={false} columns={columns} />
                ) : 
                (
                    <CRUDTableSkeleton columns={columns} loading={loaded} />
                )
            }
            
            <div style={{width: '100%', textAlign: 'center', marginTop: '15px'}}>
                <Pagination onChange={cambioPagina} pageSize={5} total={maestros.total} responsive={true} current={params.pagina} />
            </div>
        </>
    )
}
