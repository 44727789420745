import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Col, Skeleton, Empty, Row, Tabs } from 'antd';
import { FileWordFilled, FileImageFilled, FilePdfFilled, FileExcelFilled } from '@ant-design/icons';
import { viewActividad } from '../../actions/usuarios';
import { UsuarioTextScreen } from './UsuarioTextScreen';
import { UsuarioQuizScreen } from './UsuarioQuizScreen';
import { UsuarioVideoScreen } from './UsuarioVideoScreen';
import { ActividadError } from './ActividadError';

export const ViewMakeCourseActividad = ({id, actividad, setActividad, current, docente, statusCargaVista, setIdAct, showModal, getActividad}) => {
    const dispatch = useDispatch();
    const { TabPane } = Tabs;

    //Función para renderizar tipo de actividad
    const renderActividad = (actividad) =>{
        switch(actividad.actividad.tipo){
            case 0: 
                return <UsuarioTextScreen 
                    actividad={actividad}
                    current={current}
                    setActividad={setActividad}
                    setIdAct={setIdAct}
                    getActividad={getActividad}
                />
                
            case 1: 
                return <UsuarioQuizScreen 
                    current={current} 
                    showModal={showModal} 
                    actividad={actividad}
                    setActividad={setActividad}
                    setIdAct={setIdAct}
                    getActividad={getActividad}
                />
                
            case 2: 
                return <UsuarioVideoScreen 
                    current={current} 
                    actividad={actividad}
                    setActividad={setActividad}
                    setIdAct={setIdAct}
                    getActividad={getActividad}
                />

            default:
                return <div style={{width: '100%', textAlign: 'center', marginTop: '100px',  minHeight: '250px'}}>
                    <Empty description='Error' />
                </div>
        }
    }

    useEffect(() => {
        window.scrollTo(0,0);
        dispatch(viewActividad(id, setActividad, setIdAct));
     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, id])

    return (
        <>
        <Col md={(actividad !== null) ? 18 : 24}>
        {
            (actividad !== undefined && actividad !== null) ? (
                <>

                <Row>
                    <div className='viewMakeCourse-actividad-titulo'>{current.curso.nombre} | {actividad.actividad.nombre}</div>
                </Row>

                {renderActividad(actividad)}
                
                {/*Info de curso*/}
                <Row justify='center' style={{marginTop: '20px', paddingRight: '3%', paddingLeft: '3%'}}>
                    <Col md={24}>
                        <div style={{display: 'flex'}}>
                            { (docente.img != null)
                            ? <div className="viewMakeCourse-img" style={{backgroundImage: `url(${ docente.img })`, backgroundSize: 'cover'}}></div>
                            : <div className="viewMakeCourse-iniciales">{ docente.nombre.toString().substr(0, 1) }{ docente.apellidos.toString().substr(0, 1) }</div>}
                            <div style={{display: 'flex', flexFlow: 'column'}}>
                                <div className="viewMakeCourse-titulo">Docente</div>
                                <div className="viewMakeCourse-docente-nombre">{ docente.nombre } { docente.apellidos }</div>
                            </div>
                        </div>
                        {(actividad.actividad.tipo === 0) &&
                        <Row>
                            <Tabs defaultActiveKey='1' type='card' className="usuarioTextScreen-tabs">
                                <TabPane
                                    tab={<div className="usuarioTextScreen-tab">Recursos</div>}
                                    key='1'
                                >
                                    {(actividad.data.recursos.length > 0) ?
                                        actividad.data.recursos.map(recurso=>(
                                            <div key={recurso._id} style={{display: 'flex', margin: '10px 5px'}}>
                                                {
                                                    (recurso.recurso_nombre.includes('.docx')) ? <FileWordFilled style={{color:'white',fontSize:'20px'}}/> :
                                                    (recurso.recurso_nombre.includes('.jpg')) ? <FileImageFilled style={{color:'white' ,fontSize:'20px'}} /> : 
                                                    (recurso.recurso_nombre.includes('.pdf')) ? <FilePdfFilled  style={{color:'white' ,fontSize:'20px'}} /> :
                                                    (recurso.recurso_nombre.includes('.xlsx')) && <FileExcelFilled style={{color:'white' ,fontSize:'20px'}} /> 
                                                }
                                                
                                                <div className="usuarioTextScreen-recurso-nombre">
                                                    <a href={ recurso.recurso_url } target='_blank' rel='noreferrer'>{recurso.recurso_nombre}</a>
                                                </div>
                                            
                                            </div> 
                                        ))
                                        :
                                        <div style={{color: '#fff', textAlign: 'center'}}>No hay recursos disponibles</div>
                                    }
                                </TabPane>
                            </Tabs>
                        </Row>
                        }
                    </Col>
                </Row>
                </>
            ) : (
                (actividad === undefined) ? (
                    <div style={{padding: '50px'}}> 
                        <Skeleton active paragraph={{rows:16}} />
                    </div>
                ) : (
                    <ActividadError />
                )
            )
        }
        </Col>
        </>
    )
}
