import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Dropdown, Menu, Pagination, Table, Input } from 'antd';
import {MenuOutlined, SearchOutlined, LeftOutlined} from '@ant-design/icons';
import { getCategorias, eliminarCategoria, getClasificacionCategorias } from '../../actions/admin';
import { ModalAddCategory } from './UI/ModalAddCategory';
import { CRUDTableSkeleton } from './UI/CRUDTableSkeleton';
import { Link } from 'react-router-dom';

export const AdminCategoriesScreen = () => {
    const dispatch = useDispatch();
    
    const initValue = {
        _id:'',
        nombre: ''
    };

    const [vis, setVis] = useState(false);
    const [category, setCategory] = useState(initValue);
    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState({
        pagina: 1,
        limite: 8
    });
    const [categorias, setCategorias] = useState({
        items: [],
        total: 0
    });
    const [clasificaciones, setClasificaciones] = useState([]);

    const cambioPagina = (pagina) => {
        setParams({
            ...params,
            pagina: pagina
        });
    }

    const handleEliminar = () => {
        dispatch(eliminarCategoria(category._id, params, setCategorias, setLoading));
    }

    const handleNewCategory = () => {
        setCategory(initValue);
        handleOpenAddModal();
    }

    const handleOpenAddModal = () => {
        setVis(true);
    }

    useEffect(() => {
        dispatch(getCategorias(setCategorias, params, setLoading));
    },[dispatch, params])
    useEffect(() => {
        dispatch(getClasificacionCategorias(setClasificaciones));
    },[dispatch])

    const menu = (
        <Menu>
            <Menu.Item key="editar" onClick={() =>{handleOpenAddModal()}}>
                Editar
            </Menu.Item>
            <Menu.Item key="eliminar" onClick={() =>{handleEliminar()}}>
                Eliminar
            </Menu.Item>
        </Menu>
    )

    const columns = [
        {
            title: 'Nombre',
            dataIndex: 'nombre',
            key: 'nombre'
        },
        {
            title: 'Categoria',
            render: (record) => {
                if(record.clasificacion_categoria){
                    return record.clasificacion_categoria.nombre;
                }
            }
        },
        {
            width: '10%',
            title: 'Opciones',
            dataIndex: 'opciones',
            key: 'opciones' ,
            render: (text, record) => {
                    return (
                        <Dropdown trigger={['click']}  overlay={menu}>
                            <Button 
                                shape='round' 
                                size='middle' 
                                icon={<MenuOutlined />} 
                                className="btn-crud-opciones"
                                onClick={() => {
                                    setCategory({
                                        _id: record._id,
                                        nombre: record.nombre,
                                        clasificacion: record.clasificacion_categoria.nombre
                                    })
                                }} 
                            />
                        </Dropdown>
                    )
            }
    
        }

    ]

    return (
        <>
            <div>
                <h1 className="admin-crud-titulo">Categorias</h1>
            </div>
            <Link to="/admin" className="admin-btn-atras">
                <div className="admin-btn-atras"><LeftOutlined className="icon-atras" />  atrás</div>
            </Link>
            <div style={{display: 'flex', marginBottom: '20px'}}>
                <Button className="nuevo-boton" onClick={handleNewCategory}>
                    Nueva Categoria
                </Button>
                <Input className="admin-input-buscador" placeholder="Buscar" prefix={<SearchOutlined className="search-icon" />} />
            </div>
            {
                (!loading) ?
                (
                    <Table style={{color: 'red'}} pagination={false} columns={columns} dataSource={categorias.items} />
                ) : 
                (
                    <CRUDTableSkeleton columns={columns} loading={loading}/>
                )
            }
            <div style={{width: '100%', textAlign: 'center', marginTop: '15px'}}>
                <Pagination onChange={cambioPagina} pageSize={params.limite} total={categorias.total} responsive={true} />
            </div>

            <ModalAddCategory 
                vis={vis} 
                setVis={setVis} 
                _id={category._id}
                nombre={category.nombre} 
                clasificacion={category.clasificacion}
                setCategory={setCategory} 
                initValue={initValue} 
                params={params}
                setCategorias={setCategorias}
                setLoading={setLoading}
                clasificaciones={clasificaciones}
            />
        </>
    )
}
