import React from 'react'
import PropTypes from 'prop-types'
import { StepForwardOutlined } from '@ant-design/icons'

export const VideoEndedTransition = ({ endedActionCounter, handlePreventContinue }) => {
  return (
    <div className='usuarioVideoScreen-transition-container w-100 h-100'>
        <div>
            <div className='centrar'>
                <StepForwardOutlined className='usuarioVideoScreen-transition-icon'/>
            </div>
            <div className='usuarioVideoScreen-transition-info'>Siguiente actividad {(endedActionCounter === 0) ? ' - Regirigiendo...' : `en ${endedActionCounter} segundo(s)`}</div>
            <div className='usuarioVideoScreen-transition-cancel text-center' onClick={handlePreventContinue}>Cancelar</div>
        </div>
    </div>
  )
}

VideoEndedTransition.propTypes = {
    endedActionCounter: PropTypes.number.isRequired,
    handlePreventContinue: PropTypes.func.isRequired,
}