import { message } from "antd";
import { fetchConToken, fetchResetPassword, fetchSinToken } from "../helpers/fetch";
import { types } from "../types/types";


export const startLogin = ({email, password}, closeDrawer) => {
    return async(dispatch) => {
        const resp = await fetchSinToken('login', {email, password}, 'POST');
        const body = await resp.json();

        const success = () => {
            message.info("Bienvenido");
            closeDrawer();
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime());
            
            dispatch(login({
                id: body.usuario._id,
                email: body.usuario.email,
                nombre: body.usuario.nombre,
                img: body.usuario.img,
                maestro: body.usuario.maestro,
                origen: body.usuario.origen,
                primary: body.usuario.primary,
                secondary: body.usuario.secondary,
                primary_hover: body.usuario.primary_hover,
                secondary_hover: body.usuario.secondary_hover,
                primary_light: body.usuario.primary_light,
                primary_opacity: body.usuario.primary_opacity,
                secondary_opacity: body.usuario.secondary_opacity,
                background_color: body.usuario.background_color,
                sidebar_background_color: body.usuario.sidebar_background_color,
                sidebar_text_color: body.usuario.sidebar_text_color,
                sidebar_folder: body.usuario.sidebar_folder,
                sidebar_btn_user_primary_color: body.usuario.sidebar_btn_user_primary_color,
                sidebar_btn_user_secondary_color: body.usuario.sidebar_btn_user_secondary_color,
                footer_text_color: body.usuario.footer_text_color,
                navbar_text_color: body.usuario.navbar_text_color,
                navbar_border: body.usuario.navbar_border,
                navbar_primary_color: body.usuario.navbar_primary_color,
                navbar_secondary_color: body.usuario.navbar_secondary_color,
                navbar_line: body.usuario.navbar_line
            }))

            success();
        }else{
            error();
        }
            
    }
}

export const autoLogin = (token = '') => {
    return async(dispatch) => {
        const resp = await fetchConToken("auto-login", {}, 'GET', token);
        const body = await resp.json();

        if(resp.ok === true){

            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime());

            dispatch(login({
                id: body.usuario._id,
                email: body.usuario.email,
                nombre: body.usuario.nombre,
                img: body.usuario.img,
                maestro: body.usuario.maestro,
                origen: body.usuario.origen,
                primary: body.usuario.primary,
                secondary: body.usuario.secondary,
                primary_hover: body.usuario.primary_hover,
                secondary_hover: body.usuario.secondary_hover,
                primary_light: body.usuario.primary_light,
                primary_opacity: body.usuario.primary_opacity,
                secondary_opacity: body.usuario.secondary_opacity,
                background_color: body.usuario.background_color,
                sidebar_background_color: body.usuario.sidebar_background_color,
                sidebar_text_color: body.usuario.sidebar_text_color,
                sidebar_folder: body.usuario.sidebar_folder,
                sidebar_btn_user_primary_color: body.usuario.sidebar_btn_user_primary_color,
                sidebar_btn_user_secondary_color: body.usuario.sidebar_btn_user_secondary_color,
                footer_text_color: body.usuario.footer_text_color,
                navbar_text_color: body.usuario.navbar_text_color,
                navbar_border: body.usuario.navbar_border,
                navbar_primary_color: body.usuario.navbar_primary_color,
                navbar_secondary_color: body.usuario.navbar_secondary_color,
                navbar_line: body.usuario.navbar_line
            }))

            dispatch(loginUsuarioInfo({
                id: body.usuario._id,
                email: body.usuario.email,
                nombre: body.usuario.nombre,
                img: body.usuario.img,
                maestro: body.usuario.maestro
            }))
        }else{
            dispatch(checkingFinish());
        }
    }
}

const checkingFinish = () => ({
    type: types.authCheckingFinish
})

const login = ( user ) => ({
    type: types.authLogin,
    payload: user
})

const loginUsuarioInfo = ( user ) => ({
    type: types.usuariosLogin,
    payload: user
})

export const startLogout = () => {
    return( dispatch ) => {
        localStorage.clear();
        dispatch(logout());
    }
}

const logout = () => ({
    type: types.authLogout
})

export const startSignup = ({email, password, nombre, apellidos}, closeDrawer) => {
    return async(dispatch) => {
        const resp = await fetchSinToken('signup', {email, password, nombre, apellidos}, 'POST');
        const body = await resp.json();

        const datos = {
            email: email,
            password: password
        };

        const success = () => {
            message.success("Usuario Registrado con éxito");
            dispatch(startLogin(datos, closeDrawer));
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }

    }
}

// Maestro

export const startLoginMaestro = ({email, password}) => {
    return async(dispatch) => {
        const resp = await fetchSinToken('master/login', {email, password}, 'POST');
        const body = await resp.json();

        const success = () => {
            message.info("Bienvenido");
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime());

            dispatch(login({
                id: body.usuario._id,
                nombre: body.usuario.nombre
            }))

            success();
        }else{
            error();
        }
            
    }
}

export const autoLoginMaestro = (token = '') => {
    return async(dispatch) => {
        const resp = await fetchConToken("master/auto-login", {}, 'GET', token);
        const body = await resp.json();

        if(resp.ok === true){
            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime());

            dispatch(login({
                id: body.usuario._id,
                nombre: body.usuario.nombre
            }))
        }else{
            dispatch(checkingFinish());
        }
    }
}

export const convertirseMaestro = () => {
    return async(dispatch) => {
        const resp = await fetchConToken("activar-maestro", {}, 'POST');
        const body = await resp.json();
        if(body.status === true){
            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime());
            
            window.location.href = "/docente";
        }else{
            
        }
    }
}



//Admin

export const startLoginAdmin = ({email, password}) => {
    return async(dispatch) => {
        const resp = await fetchSinToken('admin/login', {email, password}, 'POST');
        const body = await resp.json();

        const success = () => {
            message.info("Bienvenido");
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime());

            dispatch(login({
                id: body.usuario._id,
                nombre: body.usuario.nombre
            }))

            success();
        }else{
            error();
        }
            
    }
}

export const autoLoginAdmin = (token = '') => {
    return async(dispatch) => {
        const resp = await fetchConToken("admin/auto-login", {}, 'GET', token);
        const body = await resp.json();

        if(resp.ok === true){

            localStorage.setItem('token', body.token );
            localStorage.setItem('token-init-date', new Date().getTime());

            dispatch(login({
                id: body.usuario._id,
                nombre: body.usuario.nombre
            }))
        }else{
            dispatch(checkingFinish());
        }
    }
}

//Recuperar Cuenta
export const resetPasswordRequest = (email, setSuccess) => {
    return async() => {
        const resp = await fetchSinToken("reset-password-request", {email}, 'POST');
        const body = await resp.json();

        const success = () => {
            setSuccess(true);
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const resetPassword = (password, token, setSuccess) => {
    return async() => {
        console.log(token);
        const resp = await fetchResetPassword("reset-password", {password}, token);
        const body = await resp.json();

        const success = () => {
            setSuccess(true);
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const resetPasswordRequestDocente = (email, setSuccess) => {
    return async() => {
        const resp = await fetchSinToken("master/reset-password-request", {email}, 'POST');
        const body = await resp.json();

        const success = () => {
            setSuccess(true);
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}

export const resetPasswordDocente = (password, token, setSuccess) => {
    return async() => {
        console.log(token);
        const resp = await fetchResetPassword("master/reset-password", {password}, token);
        const body = await resp.json();

        const success = () => {
            setSuccess(true);
        }

        const error = () => {
            message.error(body.msg);
        }

        if(resp.ok === true){
            success();
        }else{
            error();
        }
    }
}