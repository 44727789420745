
import React, { useEffect } from 'react';
import { Layout, Space, } from 'antd';
import {  UserOutlined,LockOutlined } from '@ant-design/icons';
import {  Divider } from 'antd'
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
//import { Link } from 'react-router-dom';
import { FormUsuario } from './FormUsuario';
import { PasswdUsuario } from './PasswdUsuario';
import { usuariosStartLoading } from '../../actions/usuarios';

const { TabPane } = Tabs;

const { Content } = Layout;

export const UsuarioScreen = () => {
  const dispatch = useDispatch();
  const {id:_id} = useSelector(state => state.auth);
  const {usuario} = useSelector(state => state.usuarios);

  useEffect(() => {
    dispatch(usuariosStartLoading(_id));
 }, [dispatch,_id]);
  
    return (
        <div >            
              <Content>
                <div style={{ padding: 24,backgroundColor:'white',width:'100%'}}>
                <div>
                <p style={{fontSize:'18px'}}><span className="info">Correo:</span> {usuario.email}</p>
                <p style={{fontSize:'18px'}}><span className="info">Nombre:</span> {usuario.nombre}</p>
                <p style={{fontSize:'18px'}}><span className="info">Apellidos:</span> {usuario.apellidos}</p>
                </div>
            
                    <div>
                    <Divider  orientation="left" style={{marginBottom:'1px'}}></Divider>
                    <Space align="center">
                    <Tabs defaultActiveKey="1">
                        <TabPane
                          tab={
                            <span className="tabs">
                              <UserOutlined />
                              Actualizar datos del Usuario
                            </span>
                          }
                          key="1"
                        >
                          {
                            (usuario._id) && <FormUsuario _id={usuario._id} nombre={usuario.nombre} apellidos={usuario.apellidos} imagen={usuario.img}/>
                          }
                          
                        </TabPane>
                      
                        <TabPane
                          tab={
                            <span  className="tabs">
                              <LockOutlined />
                              Cambio de Contraseña
                            </span>
                          }
                          key="3"
                        >
                         <PasswdUsuario />
                        </TabPane>
                      </Tabs>
                    </Space>
                    <br />
                
                    </div>
                </div>
              </Content>
         
        </div>
       
    )
}
