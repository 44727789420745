import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getMaestros } from '../../actions/usuarios';
import { Card, Row, Col, Pagination, Input, Form, Skeleton, Button } from 'antd';
import { Link } from 'react-router-dom';
import { LeftOutlined, SearchOutlined } from '@ant-design/icons';

export const MaestrosScreen = () => {
    const dispatch = useDispatch();

    const [params, setParams] = useState({
        pagina: 1,
        search: '',
        filter: 0,
        filter_key: ''
    });
    const [loaded, setLoaded] = useState(true);
    const [maestros, setMaestros] = useState({
        maestros: [],
        total: 8
    });

    useEffect(() => {
        dispatch(getMaestros(setMaestros, params, setLoaded));
    }, [dispatch, params]);

    const cambioPagina = (pag) => {
        setParams({
            ...params,
            pagina: pag
        });
    }

    const handleBuscar = (search) => {
        setParams({
            ...params,
            pagina: 1,
            search: search.nombre
        });
    }

    const handleBuscando = () => {
        setLoaded(true);
    }

    return (
        <div style={{padding:'2% 5%'}}>
            <Link to="/" style={{display: 'inline'}}>
                <div className="btn-atras"><LeftOutlined className="icon-atras" />  maestros</div>
            </Link>
            <Form onFinish={handleBuscar} onValuesChange={handleBuscando}>
                <Form.Item name="nombre">
                    <Input className="input-buscador" placeholder="Buscar" prefix={<Button type="text" htmlType="submit" style={{padding: '5px !important'}}><SearchOutlined className="search-icon" /></Button>}/>
                </Form.Item>
            </Form>

            <Row gutter={[16, 16]}>
            <Col md={19} sm={24}>    
                <Row gutter={[8, 8]}>
            {   
            (!loaded) 
                ? ( 
                    (maestros.maestros.length > 0) 
                        ? (maestros.maestros.map((maestro) => {
                            return (
                                <>
                                <Col xs={24} sm={12} md={6} key={maestro._id}> 
                                    <Link to={"/maestro/" + maestro._id}>
                                    <Card hoverable style={{height: '200px'}} key={maestro._id}>
                                        <div className="maestro-card">
                                            {
                                                (maestro.img)
                                                ?(<img className="maestro-card-img" src={maestro.img} alt=""/> )
                                                :(<div className="maestro-card-iniciales">{maestro.iniciales}</div>)
                                            }
                                            <div className="maestro-card-nombre bold">{maestro.nombre}</div>
                                            <div className="maestro-card-profesion">{maestro.profesion}</div>
                                        </div>
                                    </Card>
                                    </Link>
                                </Col>
                                </>
                            )})
                            ):(<div className="no-disponible">No hay maestros por mostrar</div>)
                ):(       
                    [0,1,2,3,4,5,6,7].map(n => (
                        <Col className="gutter-row" xs={24} sm={12} md={12} lg={6}>     
                            <Card style={{height: '200px'}}>
                                <div className="maestro-card-skeleton">
                                    <Skeleton.Avatar active className="maestro-skeleton-img" shape="circle"/>
                                    <Skeleton title={false} paragraph={{ rows: 2, width: '100%' }} />
                                </div>
                            </Card>
                        </Col>
                    ))
                )
            }
                </Row>
            </Col>
            <Col md={5} sm={12} className="maestros-filtros-card">
                <div className="maestros-filtros">Filtros</div>
            </Col>
            </Row>
            <Row>
                <div style={{width: '100%', textAlign: 'center', marginTop: '15px'}}>
                    <Pagination onChange={cambioPagina} current={params.pagina} pageSize={8} total={maestros.total} responsive={true} />
                </div>
            </Row>
        </div>
    )
}
