import React, { useState } from 'react';
import { Alert, Button, Form, Input, message } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { defaultValidateMessages } from '../../helpers/validateMessages';
import { Link, useLocation } from 'react-router-dom';
import { resetPasswordDocente } from '../../actions/auth';
import { useDispatch } from 'react-redux';

export const RecPassFormDocenteScreen = () => {

    const [success, setSuccess] = useState(false);

    const dispatch = useDispatch();

    const {search} = useLocation();
    const query = new URLSearchParams(search);

    const token = (query.get("token"));

    const validatePassword = (datos) => {
        if(datos.password !== datos.confPassword){
            message.info('Las contraseñas no coinciden');
        }
        else{
            dispatch(resetPasswordDocente(datos.password, token, setSuccess));
        }
      };

    return (
        <div>
            <div  className="auth-center">
                <div className="pass-card">
                    {
                        (success === true)
                            ?
                                <div>
                                    <Alert
                                        type="success"
                                        message="Contraseña Actualizada"
                                        description="Tu contraseña se ha reestablecido correctamente"
                                        showIcon
                                    />

                                    <Link to="/docente/auth">
                                        <Button block style={{marginTop: '20%', borderColor: 'var(--primary)', color: 'var(--primary)'}}>Ingresa con tu nueva contraseña</Button>
                                    </Link>
                                </div>

                            :
                                <div> 
                                    
                                    <h2>Actualiza tu contraseña</h2>

                                    <p>Ingresa una nueva cotraseña para tu cuenta</p>

                                    <Form
                                        validateMessages={defaultValidateMessages}
                                        onFinish={validatePassword}
                                    >
                                        <Form.Item
                                            name="password"
                                            rules={[
                                                {
                                                    required: true,
                                                    min: 8
                                                }
                                            ]}
                                        >
                                            <Input
                                                type="password"
                                                prefix={<LockOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />}
                                                placeholder="Contraseña"
                                                autoComplete="off"
                                            >
                                            </Input>
                                        </Form.Item>

                                        <Form.Item
                                            name="confPassword"
                                            rules={[
                                                {
                                                    required: true,
                                                    min: 8
                                                }
                                            ]}
                                        >
                                            <Input
                                                type="password"
                                                prefix={<LockOutlined style={{color: 'var(--primary)'}} className="site-form-item-icon" />}
                                                placeholder="Confirmar Contraseña"
                                                autoComplete="off"
                                            >
                                            </Input>
                                        </Form.Item>

                                        <div style={{float: 'right'}} >
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" className="login-form-button btn-login">Actualizar</Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                    
                                </div>
                    }
                </div>
            </div>
        </div>
    )
}
