import React, { useState, useEffect } from 'react';
import { UserOutlined, IdcardOutlined, ReconciliationOutlined, RiseOutlined, FallOutlined, BankOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { getEstadisticas } from '../../actions/admin';

export const AdminEstadisticasScreen = () => {
    const dispatch = useDispatch();
    const [estadisticas, setEstadisticas] = useState({
        totales: {
            usuarios: 0,
            maestros: 0,
            cursos: 0
        },
        tendencias: {
            usuarios: 0,
            usuarios_porcentaje: 0,
            usuarios_incremento: false,
            inscritos: 0,
            inscritos_porcentaje: 0,
            inscritos_incremento: false,
            maestros: 0,
            maestros_porcentaje: 0,
            maestros_incremento: false,
            cursos: 0,
            cursos_porcentaje: 0,
            cursos_incremento: false
        },
        ingresos: {
            total: 0,
            ingresos: 0,
            ingresos_porcentaje: 0,
            ingresos_incremento: 0
        }
    });
    const {totales, tendencias, ingresos} = estadisticas;
    useEffect(() => {
        dispatch(getEstadisticas(setEstadisticas));
    }, [dispatch])

    return (
        <>
            <div className="admin-estadisticas-card">
                <div className="admin-estadisticas-titulo">Totales</div>
                <div className="admin-estadisticas-container">
                    <div className="admin-estadisticas-item">
                        <UserOutlined className="admin-estadisticas-icon" style={{color: '#80cbc4'}}/>
                        <div className="admin-estadisticas-subtitulo">Usuarios</div>
                        <div className="admin-estadisticas-data">{totales.usuarios}</div>
                    </div>
                    <div className="admin-estadisticas-item">
                        <IdcardOutlined className="admin-estadisticas-icon" style={{color: '#ed4882'}}/>
                        <div className="admin-estadisticas-subtitulo">Maestros</div>
                        <div className="admin-estadisticas-data">{totales.maestros}</div>
                    </div>
                    <div className="admin-estadisticas-item">
                        <ReconciliationOutlined className="admin-estadisticas-icon" style={{color: '#5c6bc1'}}/>
                        <div className="admin-estadisticas-subtitulo">Cursos</div>
                        <div className="admin-estadisticas-data">{totales.cursos}</div>
                    </div>
                </div>
            </div>
            <div className="admin-estadisticas-card">
                <div className="admin-estadisticas-titulo">Tendencia</div>
                <div className="admin-estadisticas-container">
                    <div className="admin-estadisticas-item">
                        <div className={`admin-estadisticas-procentaje ${tendencias.usuarios_incremento ? "green" : "red"}`}>
                            {tendencias.usuarios_porcentaje}%
                        </div>
                        <div className="admin-estadisticas-subtitulo">
                            Usuarios 
                            {(tendencias.usuarios_incremento) ?
                                <RiseOutlined /> : <FallOutlined />
                            }
                        </div>
                        <div className="admin-estadisticas-data">{tendencias.usuarios}</div>
                    </div>
                    <div className="admin-estadisticas-item">
                        <div className={`admin-estadisticas-procentaje ${tendencias.inscritos_incremento ? "green" : "red"}`}>
                            {tendencias.inscritos_porcentaje}%
                        </div>
                        <div className="admin-estadisticas-subtitulo">
                            Inscritos
                            {(tendencias.inscritos_incremento) ?
                                <RiseOutlined /> : <FallOutlined />
                            }
                        </div>
                        <div className="admin-estadisticas-data">{tendencias.inscritos}</div>
                    </div>
                    <div className="admin-estadisticas-item">
                        <div className={`admin-estadisticas-procentaje ${tendencias.maestros_incremento ? "green" : "red"}`}>
                            {tendencias.maestros_porcentaje}%
                        </div>
                        <div className="admin-estadisticas-subtitulo">
                            Maestros
                            {(tendencias.maestros_incremento) ?
                                <RiseOutlined /> : <FallOutlined />
                            }
                        </div>
                        <div className="admin-estadisticas-data">{tendencias.maestros}</div>
                    </div>
                    <div className="admin-estadisticas-item">
                        <div className={`admin-estadisticas-procentaje ${tendencias.cursos_incremento ? "green" : "red"}`}>
                            {tendencias.cursos_porcentaje}%
                        </div>
                        <div className="admin-estadisticas-subtitulo">
                            Cursos
                            {(tendencias.cursos_incremento) ?
                                <RiseOutlined /> : <FallOutlined />
                            }
                        </div>
                        <div className="admin-estadisticas-data">{tendencias.cursos}</div>
                    </div>
                </div>
            </div>
            <div className="admin-estadisticas-card">
                <div className="admin-estadisticas-titulo">Ingresos</div>
                <div className="admin-estadisticas-container">
                    <div className="admin-estadisticas-item">
                        <BankOutlined className="admin-estadisticas-icon" style={{color: '#2dbaf6'}}/>
                        <div className="admin-estadisticas-subtitulo">Total Ingresos</div>
                        <div className="admin-estadisticas-data">{ingresos.total}</div>
                    </div>
                    <div className="admin-estadisticas-item">
                        <div className={`admin-estadisticas-procentaje ${ingresos.ingresos_incremento ? "green" : "red"}`}>
                            {ingresos.ingresos_porcentaje}%
                        </div>
                        <div className="admin-estadisticas-subtitulo">
                            Ingresos
                            {(ingresos.ingresos_incremento) ?
                                <RiseOutlined /> : <FallOutlined />
                            }
                        </div>
                        <div className="admin-estadisticas-data">{ingresos.ingresos}</div>
                    </div>
                </div>
            </div>
        </>
    )
}
